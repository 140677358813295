<div class="container">
  <div class="row">
    <div class="card">
      <div class="card-body">
        <h1 class="text-center">Weekly Updates &amp; Reports</h1>
      </div>
    </div>
  </div>

  <div class="row">
    <!-- <button
      mat-flat-button
      color="accent"
      matTooltip="Get email updates"
      (click)="showNotificationSetup = !showNotificationSetup"
      [matTooltipPosition]="'above'"
    >
      <i class="material-icons">notification_important</i>
      Subscribe to email updates
    </button>
  -->

    <div class="card" *ngIf="showNotificationSetup">
      <h2 class="card-title text-center">
        <mat-icon>notification_important</mat-icon>
        Notifications & alerts setup
      </h2>

      <div class="card-body">
        You will recieve updates on new deliverables on

        <mat-form-field appearance="outline">
          <mat-label>email</mat-label>
          <input matInput />
        </mat-form-field>

        <button
          mat-flat-button
          color="primary"
          matTooltip="Update"
          [matTooltipPosition]="'above'"
        >
          <i class="material-icons">save</i>
          Update
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <mat-form-field appearance="outline">
      <mat-label>Deliverable type</mat-label>
      <mat-select
        [value]="deliverableTypeSelected"
        (selectionChange)="deliverableTypeSelectedChanged($event.value)"
      >
        <mat-option value="all">ALL</mat-option>
        <mat-option *ngFor="let delType of deliverableTypes" [value]="delType">
          {{ delType | uppercase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-card *ngIf="this.auth.isSuperAdmin">
    <mat-card-header>
      <mat-card-title>SuperAdmin Section</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <button
        color="accent"
        mat-raised-button
        [routerLink]="['../deliverables-add']"
      >
        <mat-icon>add</mat-icon>
        Add Deliverable
      </button>
    </mat-card-content>
  </mat-card>

  <div class="row">
    <div class="col-md-12" *ngFor="let deliverable of deliverables">
      <app-deliverable-card
        [deliverable]="deliverable"
        showAdminControls="auth.isSuperAdmin"
      ></app-deliverable-card>
    </div>
  </div>
</div>
