import { Component, OnInit } from '@angular/core';
import { InvoicesService } from '../services/invoices.service';
import { Subscription, Observable, of } from 'rxjs';
import { IInvoice, INVOICE_STATUS, CURRENCY } from '../models/invoice';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import * as dayjs from 'dayjs';
import { FILE_TYPE } from '../services/files.service';
import { AccountService } from '../services/account.service';
import { ProjectService } from '../services/project.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-invoices-add',
  templateUrl: './invoices-add.component.html',
  styleUrls: ['./invoices-add.component.scss'],
})
export class InvoicesAddComponent implements OnInit {
  fileType = FILE_TYPE.INVOICE;
  subscription = new Subscription();
  status_types = Object.values(INVOICE_STATUS);
  currency_types = Object.values(CURRENCY);

  id = '';
  accountId = '';
  projectId = '';
  invoicesRoute = [];

  ownerName = of('');

  invoice: IInvoice = {
    account: '',
    name: '',
    created: new Date(),
    issued: new Date(),
    price: 0,
    project: '',
    author: '',
    dueDate: dayjs().add(1, 'month').toDate(),
    status: INVOICE_STATUS.UNPAID,
    url: '',
    currency: CURRENCY.USD,
  };

  constructor(
    private invoicesService: InvoicesService,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private projectService: ProjectService,
    private accountService: AccountService
  ) {
    this.invoice.author = this.auth.email;
    this.id = activatedRoute.snapshot.paramMap.get('invoiceId');
    this.accountId = activatedRoute.snapshot.paramMap.get('accountId') || '';
    this.projectId = activatedRoute.snapshot.paramMap.get('projectId') || '';

    this.invoicesRoute = this.projectId
      ? ['/account', this.accountId, 'project', this.projectId, 'invoices']
      : ['/account', this.accountId, 'invoices'];

    this.ownerName = this.projectId
      ? projectService
          .getProject$(this.projectId)
          .pipe(map((proj) => proj.name))
      : this.accountId
      ? accountService
          .getAccount$(this.accountId)
          .pipe(map((proj) => proj.name))
      : of('');

    this.invoice.account = this.accountId;
    this.invoice.project = this.projectId;
    if (this.id) {
      invoicesService.getInvoice$(this.id).subscribe((invoice) => {
        this.invoice = invoice;
      });
    }
  }

  ngOnInit(): void {}

  submit(): void {
    if (this.id) {
      this.invoicesService.updateInvoice(this.invoice);
    } else {
      this.invoicesService.createInvoice(this.invoice);
    }
  }

  docUploaded(url: string) {
    this.invoice.url = url;
  }
}
