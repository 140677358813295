<div class="card">
  <div class="card-header card-header-warning">
    <h4 class="card-title">Team Working With you</h4>
    <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
  </div>
  <div class="card-body table-responsive">
    <table class="table table-hover">
      <thead class="text-warning">
        <th>Name</th>
        <th>Specialisation</th>
        <th>Contact</th>
        <th *ngIf="edit" colspan="2"></th>
      </thead>
      <tbody>
        <tr *ngFor="let expert of experts">
          <td>{{ expert.name }}</td>
          <td>{{ expert.title }}</td>
          <td>
            {{ expert.email }}
          </td>
          <ng-container *ngIf="edit">
            <td>
              <button mat-mini-fab (click)="editExpert(expert)">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
            <td>
              <button mat-mini-fab color="warn" (click)="removeExpert(expert)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
    <button *ngIf="edit" mat-raised-button color="accent" (click)="addExpert()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
