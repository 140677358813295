<div *ngIf="files.length < limit">
  <div
    class="dropzone"
    dropzone
    (hovered)="toggleHover($event)"
    (dropped)="onDrop($event)"
    [class.hovering]="isHovering"    
  >
    <h3>{{ title }}</h3>
    <p>Drag and Drop a File</p>
  </div>
</div>

<div *ngIf="files.length">
  <h3>Uploads</h3>
  <div *ngFor="let file of files">
    <uploader-task   
      [type]="fileType"
      [file]="file"
      (fileUrl)="uploadedFile($event)"
      [showDelete]="showDelete"
    ></uploader-task>
    
  </div>
</div>
