import { Component, OnInit } from '@angular/core';
import { DashboardsService } from '../services/dashboards.service';
import { Subscription, Observable, of } from 'rxjs';
import { IDashboard } from '../models/dashboard';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-dashboard-views-add',
  templateUrl: './dashboard-views-add.component.html',
  styleUrls: ['./dashboard-views-add.component.scss'],
})
export class DashboardsAddComponent implements OnInit {
  subscription = new Subscription();

  id = '';
  projectId = '';
  accountId = '';

  dashboard: IDashboard = {
    name: '',
    created: new Date(),
    height: 1000,
    project: '',
    account: '',
    url: '',
    author: '',
    description: '',
  };

  constructor(
    private dashboardsService: DashboardsService,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService
  ) {
    this.id = activatedRoute.snapshot.paramMap.get('dashboardId');
    this.projectId = activatedRoute.snapshot.paramMap.get('projectId');
    this.dashboard.project = this.projectId;
    this.accountId = activatedRoute.snapshot.paramMap.get('accountId');
    this.dashboard.account = this.accountId;
    if (this.id) {
      dashboardsService
        .getDashboard(this.id)
        .subscribe((dashboard) => (this.dashboard = dashboard));
    } else {
      this.dashboard.author = this.auth.email;
    }
  }

  ngOnInit(): void {}

  submit(): void {
    if (this.id) {
      this.dashboardsService.updateDashboard(this.dashboard);
    } else {
      this.dashboardsService.createDashboard(this.dashboard);
    }
  }
}
