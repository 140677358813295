import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlsToHrefs',
})
export class UrlsToHrefsPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    return value
      .split(' ')
      .map((word) =>
        word.startsWith('http') || word.startsWith('https')
          ? `<a href="${word}" target="_blank"
          rel="noopener"><u>${word}</u></a>`
          : word
      )
      .join(' ');
  }
}
