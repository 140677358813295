import { Component, OnInit } from '@angular/core';
import { IDeliverable, DELIVERABLE_TYPE } from '../models/deliverable';
import { DeliverableService } from '../services/deliverable.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
// import * as Chartist from 'chartist';

@Component({
  selector: 'app-deliverables',
  templateUrl: './deliverables.component.html',
  styleUrls: ['./deliverables.component.css'],
})
export class DeliverablesComponent implements OnInit {
  showNotificationSetup = false;
  deliverableTypeSelected = 'all';
  deliverableTypes = Object.values(DELIVERABLE_TYPE);
  accountId = '';
  projectId = '';
  allDeliverables: IDeliverable[] = [];
  deliverables: IDeliverable[] = [];

  constructor(
    public deliverablesService: DeliverableService,
    public auth: AuthService,
    private activatedRoute: ActivatedRoute
  ) {
    this.accountId = activatedRoute.snapshot.paramMap.get('accountId');
    this.projectId = activatedRoute.snapshot.paramMap.get('projectId');
    this.deliverablesService
      .getDeliverablesForProject(this.projectId)
      .subscribe((deliverables) => {
        console.log(deliverables, this.deliverableTypeSelected);
        this.allDeliverables = deliverables;
        this.deliverables = this.filterSelectedDeliverables(
          this.allDeliverables,
          this.deliverableTypeSelected
        );
      });
  }
  ngOnInit() {}

  filterSelectedDeliverables(
    deliverables: IDeliverable[],
    selectedType: string
  ) {
    return deliverables.filter(
      (deliverable) =>
        selectedType === 'all' || deliverable.type === selectedType
    );
  }

  deliverableTypeSelectedChanged(val: string) {
    this.deliverableTypeSelected = val;
    this.deliverables = [
      ...this.filterSelectedDeliverables(
        this.allDeliverables,
        this.deliverableTypeSelected
      ),
    ];
  }
}
