import { firestore } from 'firebase';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentSnapshotDoesNotExist,
  Action,
  DocumentSnapshotExists,
  DocumentChangeAction,
} from '@angular/fire/firestore';

import { combineLatest, defer, of, Observable } from 'rxjs';
import { map, switchMap, tap, finalize } from 'rxjs/operators';

export function transformFirebase<T>(firebaseObject: any): T {
  let transformed = { ...firebaseObject };
  for (const key in firebaseObject) {
    if (
      firebaseObject.hasOwnProperty(key) &&
      firebaseObject[key] instanceof firestore.Timestamp
    ) {
      transformed[key] = firebaseObject[key].toDate();
    }    
  }  
  return transformed;
}

export function getDocs<T>(
  afs: AngularFirestore,
  collection: string,
  ids: string[]
): Observable<T[]> {
  ids = (ids ?? []).filter((id) => (typeof id === 'string') && id.length > 0);
  if (ids.length === 0) {
    return of([]);
  }
  return combineLatest(
    
    ids.map((id) => {
      console.log(id)
      console.log(`${collection}/${id}`);
      return afs
        .doc<T>(`${collection}/${id}`)
        .valueChanges()
        .pipe(map((d) => transformFirebase<T>(d)));
    })
  );
}

export const docJoin = (
  afs: AngularFirestore,
  paths: { [key: string]: string }
) => {
  return (source) =>
    defer(() => {
      let parent;
      const keys = Object.keys(paths);

      return source.pipe(
        switchMap((data) => {
          // Save the parent data state
          parent = data;

          // Map each path to an Observable
          const docs$ = keys.map((k) => {
            const fullPath = `${paths[k]}/${parent[k]}`;
            return afs.doc(fullPath).valueChanges();
          });

          // return combineLatest, it waits for all reads to finish
          return combineLatest(docs$);
        }),
        map((arr) => {
          // We now have all the associated douments
          // Reduce them to a single object based on the parent's keys
          const joins = keys.reduce((acc, cur, idx) => {
            return { ...acc, [cur]: arr[idx] };
          }, {});

          // Return the parent doc with the joined objects
          return { ...parent, ...joins };
        })
      );
    });
};

export const leftJoin = (
  afs: AngularFirestore,
  field,
  collection,
  limit = 100
) => {
  /**
   * @usageNotes Example usage
   * ```
   * const users$ = afs.collection('users').valueChanges()
   * const usersOrders$ = users$.pipe(
   *   leftJoin(afs, 'userId', 'orders')
   * )
   *
   * ///// result: users collection with orders array on each doc /////
   *
   * [
   *   { ...user1Data, orders: [{ orderNo: 'A', userId: 'jeff' }, { orderNo: 'B', userId: 'jeff' }]},
   *   { ...user2Data, orders: [...]}
   * ]```
   *
   */
  return (source) =>
    defer(() => {
      // Operator state
      let collectionData;

      // Track total num of joined doc reads
      let totalJoins = 0;

      return source.pipe(
        switchMap((data) => {
          // Clear mapping on each emitted val ;
          // Save the parent data state
          collectionData = data as any[];

          const reads$ = [];
          for (const doc of collectionData) {
            // Push doc read to Array
            if (doc[field]) {
              // Perform query on join key, with optional limit
              const q = (ref) =>
                ref.where(field, '==', doc[field]).limit(limit);

              reads$.push(afs.collection(collection, q).valueChanges());
            } else {
              reads$.push(of([]));
            }
          }

          return combineLatest(reads$);
        }),
        map((joins) => {
          return collectionData.map((v, i) => {
            totalJoins += joins[i].length;
            return { ...v, [collection]: joins[i] || null };
          });
        }),
        tap((final) => {
          console.log(
            `Queried ${(final as any).length}, Joined ${totalJoins} docs`
          );
          totalJoins = 0;
        })
      );
    });
};
