import { Injectable } from '@angular/core';
import { IUser } from '../models/user';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public users$: Observable<IUser[]>;
  public users: IUser[];
  public usersMap: { [email: string]: IUser } = {};

  constructor(private afs: AngularFirestore) {
    this.users$ = this.afs.collection<IUser>('users').valueChanges({
      idField: 'id',
    });
    this.users$.subscribe((users) => {
      this.users = users;
      users.forEach((user) => {
        this.usersMap[user.email] = user;
      });
    });
  }

  getUser(id: string): Observable<IUser> {
    return this.afs.doc<IUser>(`users/${id}`).valueChanges();
  }

  updateUser({
    displayName,
    email,
    photoURL,
    name,
    roles,
    position,
    bio,
    slack,
  }: IUser) {
    const userRef = this.afs.doc(`users/${email}`);
    const data: any = {
      // displayName,
      // email,
      // id,

      // ...(projects  && { projects: projects }),
      ...(displayName && { displayName: displayName }),
      ...(email && { email: email }),
      ...(position && { position: position }),
      ...(photoURL && { photoURL: photoURL }),
      ...(name && { name: name }),
      ...(roles && { roles: roles }),
      ...(bio && { bio: bio }),
      ...(slack && { slack: slack }),
    };
    return userRef.set(data, { merge: true });
  }
}
