import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAccess } from '../models/access';
import { ILegalDocument, LegalDocument } from '../models/legal';
import { AuthService } from './auth.service';
import { transformFirebase } from './helpers';

@Injectable({
  providedIn: 'root',
})
export class LegalsService {
  public legalsMapAccounts: {
    [accountId: string]: Observable<LegalDocument[]>;
  } = {};
  public legalsMapProjects: {
    [projectId: string]: Observable<LegalDocument[]>;
  } = {};
  constructor(private afs: AngularFirestore, private afAuth: AuthService) {}

  createLegal({
    id,
    name,
    author,
    authorName,
    created,
    description,
    type,
    project,
    account,
    url,
    startDate,
    endDate,
    hasTerminationDate,
    legalStatus,
  }: LegalDocument) {
    this.afs
      .collection<ILegalDocument>(`legals`)
      .add({
        ...(id && { id: id }),
        ...(name && { name: name }),
        ...(author && { author: author }),
        ...(authorName && { authorName: authorName }),
        ...(created && { created: created }),
        ...(description && { description: description }),
        ...(type && { type: type }),
        ...{ project: project || '' },
        ...(account && { account: account }),
        ...(url && { url: url }),
        ...(startDate && { startDate: startDate }),
        ...(endDate && { endDate: endDate }),
        ...{ hasTerminationDate: hasTerminationDate },
        ...(legalStatus && { legalStatus: legalStatus }),
      })
      .then(() => {
        console.log('pls', {
          ...(id && { id: id }),
          ...(name && { name: name }),
          ...(author && { author: author }),
          ...(authorName && { authorName: authorName }),
          ...(created && { created: created }),
          ...(description && { description: description }),
          ...(type && { type: type }),
          ...{ project: project || '' },
          ...(account && { account: account }),
          ...(url && { url: url }),
          ...(startDate && { startDate: startDate }),
          ...(endDate && { endDate: endDate }),
          ...{ hasTerminationDate: hasTerminationDate },
          ...(legalStatus && { legalStatus: legalStatus }),
        });
      })
      .catch(alert);
  }

  updateLegal({
    id,
    name,
    author,
    authorName,
    created,
    description,
    type,
    project,
    account,
    url,
    startDate,
    endDate,
    hasTerminationDate,
    legalStatus,
  }: LegalDocument) {
    console.log(
      id,
      name,
      author,
      authorName,
      created,
      description,
      type,
      project,
      account,
      url,
      startDate,
      endDate
    );
    this.afs
      .doc(`legals/${id}`)
      .set(
        {
          ...(id && { id: id }),
          ...(name && { name: name }),
          ...(author && { author: author }),
          ...(authorName && { authorName: authorName }),
          ...(created && { created: created }),
          ...(description && { description: description }),
          ...(type && { type: type }),
          ...(project && { project: project }),
          ...(account && { account: account }),
          ...(url && { url: url }),
          ...(startDate && { startDate: startDate }),
          ...(endDate && { endDate: endDate }),
          ...{ hasTerminationDate: hasTerminationDate },
          ...(legalStatus && { legalStatus: legalStatus }),
        },
        {
          merge: true,
        }
      )
      .then(() => {
        console.log('yes', id, status);
      })
      .catch(alert);
  }

  getLegal$(id: string): Observable<LegalDocument> {
    return this.afs
      .doc<LegalDocument>(`legals/${id}`)
      .valueChanges()
      .pipe(
        map((q) => transformFirebase<LegalDocument>(q)),
        map((legal) => {
          return new LegalDocument({ id: id, ...legal });
        })
      );
  }

  getLegalsForAccess$(access: IAccess) {
    if (access.project) {
      return this.getLegalsForProject$(access.project);
    }
    return this.getLegalsForAccount$(access.account);
  }

  getLegalsPerAccesses$(accesses: IAccess[]) {
    return accesses.map((access) => {
      return this.getLegalsForAccess$(access);
    });
  }

  getLegalsForProject$(projectId: string): Observable<LegalDocument[]> {
    if (!(projectId in this.legalsMapProjects)) {
      this.legalsMapProjects[projectId] = this.afs
        .collection<LegalDocument>('legals', (ref) =>
          ref.where('project', '==', projectId).orderBy('created')
        )
        .valueChanges({ idField: 'id' })
        .pipe(
          map((q) =>
            q.map((item) => new LegalDocument(transformFirebase<any>(item)))
          )
        );
    }
    return this.legalsMapProjects[projectId];
  }

  getLegalsForAccount$(accountId: string): Observable<LegalDocument[]> {
    if (!(accountId in this.legalsMapAccounts)) {
      this.legalsMapAccounts[accountId] = this.afs
        .collection<LegalDocument>('legals', (ref) =>
          ref
            .where('account', '==', accountId)
            .where('project', '==', '')
            .orderBy('created')
        )
        .valueChanges({ idField: 'id' })
        .pipe(
          map((q) =>
            q.map((item) => new LegalDocument(transformFirebase<any>(item)))
          )
        );
    }
    return this.legalsMapAccounts[accountId];
  }

  deleteLegal(legal: LegalDocument) {
    this.afs
      .doc(`legals/${legal.id}`)
      .delete()
      .then(() => {
        console.log('yess', legal.id);
      })
      .catch((err) => console.log('err', err));
  }
}
