<div class="container">
  <div class="row">
    <div class="card">
      <div class="card-body">
        <h1 class="text-center">Invoices</h1>
      </div>
    </div>
  </div>
  <ng-container *ngIf="res | async as groups">
    <div class="col-md-12" *ngFor="let group of groups; index as i">
      <div class="card">
        <div class="card-header card-header-warning">
          <h4 class="card-title">
            {{ group.name | async }}
          </h4>
          <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
        </div>
        <div class="card-body table-responsive">
          <table class="table table-hover">
            <thead class="text-warning">
              <!-- <th>ID</th> -->
              <th>Issued</th>
              <th>Due Date</th>
              <th>Name</th>
              <th>Price</th>
              <th>Status</th>
              <th colspan="2"></th>
              <th *ngIf="this.auth.isSuperAdmin" colspan="2"></th>
            </thead>
            <tbody>
              <tr *ngFor="let invoice of group.invoices | async">
                <td>{{ invoice.issued | date }}</td>
                <td matTooltip="{{ invoice.dueDate | relativeTime }}">
                  {{ invoice.dueDate | date }}
                </td>
                <td>
                  
                  <!-- {{ invoice.account }} | {{ invoice.project }} | -->
                  <strong>{{ invoice.name }}</strong>
                </td>
                <td>
                  {{ invoice.price | currency: invoice.currency }}
                </td>
                <td>
                  {{ invoice.status }}<br />
                  <div [ngSwitch]="invoice.status">
                    <mat-icon *ngSwitchCase="'paid'">check_circle</mat-icon>
                    <mat-icon *ngSwitchCase="'in transfer'"
                      >swap_horizontal_circle</mat-icon
                    >
                    <mat-icon *ngSwitchCase="'unpaid'">warning</mat-icon>
                  </div>
                </td>
                <td>
                  <a
                    mat-flat-button
                    color="accent"
                    matTooltip="Download"
                    [href]="invoice.url"
                    target="_blank"
                    [matTooltipPosition]="'above'"
                  >
                    <i class="material-icons">cloud_download</i>
                    Download
                  </a>
                </td>
                <td>
                  <button
                    mat-flat-button
                    color="accent"
                    [disabled]="invoice.status !== INVOICE_STATUS.UNPAID"
                    matTooltip="Mark as Paid"
                    [matTooltipPosition]="'above'"
                    (click)="markAsPaid(invoice)"
                  >
                    <i class="material-icons">money</i>
                    Mark as Paid
                  </button>
                </td>
                <ng-container *ngIf="this.auth.isSuperAdmin">
                  <td>
                    <button
                      mat-flat-button
                      color="accent"
                      matTooltip="Edit Invoice"
                      [matTooltipPosition]="'above'"
                      (click)="editInvoice(invoice)"
                    >
                      <i class="material-icons">edit</i>
                      Edit
                    </button>
                  </td>
                  <td>
                    <button
                      mat-flat-button
                      color="warn"
                      matTooltip="Edit Invoice"
                      [matTooltipPosition]="'above'"
                      (click)="deleteInvoice(invoice)"
                    >
                      <i class="material-icons">delete</i>
                      Delete
                    </button>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
  <mat-card *ngIf="auth.isSuperAdmin">
    <mat-card-header>
      <mat-card-title>SuperAdmin Section</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <button
        *ngIf="this.auth.isSuperAdmin"
        mat-flat-button
        color="accent"
        matTooltip="Add Invoice"
        [matTooltipPosition]="'above'"
        [routerLink]="['../invoices-add']"
      >
        <i class="material-icons">add</i>
        Add invoice
      </button>
    </mat-card-content>
  </mat-card>
</div>
