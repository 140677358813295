import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProjectService } from '../services/project.service';
import { AccountService } from '../services/account.service';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { IAccount } from '../models/account';
import { IProject } from '../models/project';
import { AuthService } from '../services/auth.service';

@Component({
  templateUrl: './account-overview.component.html',
  styleUrls: ['./account-overview.component.scss'],
})
export class AccountOverviewComponent implements OnInit, OnDestroy {
  projects$: Observable<IProject[]>;
  account: IAccount;
  accountId = '';
  subscribtion = new Subscription();
  constructor(
    public projectService: ProjectService,
    public accountService: AccountService,
    public auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.subscribtion.add(
      route.paramMap.subscribe((params) => {
        this.accountId = params.get('accountId');
        this.account = this.accountId
          ? accountService.accountsMap[this.accountId]
          : null;
        console.log(this.accountId);
        if (this.accountId) {
          this.projects$ = projectService.getMyProjects(this.accountId);
        }
      })
    );
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.subscribtion.unsubscribe();
  }

  remove(project: IProject) {
    if (confirm(`Are you sure you want to DELETE ${project.name}?`)) {
      if (
        confirm(
          `Are you really really sure you want to DELETE ${project.name}?`
        )
      ) {
        this.projectService.deleteProject(project);
      }
    }
  }
}
