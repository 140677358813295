<div class="container">
  <div class="row">
    <div class="card">
      <div class="card-body">
        <h1 class="text-center">Add Account</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <mat-card class="col-md-12">
      <mat-card-content>
        <mat-form-field appearance="outline">
          <mat-label>name</mat-label>
          <input matInput [(ngModel)]="account.name" />
        </mat-form-field>
        <br />
        <mat-form-field appearance="outline">
          <mat-label>Projects</mat-label>
          <mat-select [(value)]="account.projects" multiple>
            <mat-option
              *ngFor="let project of projects$ | async"
              [value]="project.id"
              >{{ project.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <br />

        <!-- <mat-form-field appearance="outline">
        <mat-label>project</mat-label>
        <input matInput [(ngModel)]="account.project" />
      </mat-form-field> -->
        <mat-label>Account Logo</mat-label><br />
        <img width="200" *ngIf="account.logo" [src]="account.logo" />
        <app-uploader
          [fileType]="fileType"
          title="Upload Account logo"
          (fileDownloadURL)="logoUploaded($event)"
        ></app-uploader>

        <br />
        <mat-label>Billing info</mat-label>
        <app-media-editor [(text)]="account.billingInfo"></app-media-editor>
        <br />
      </mat-card-content>
      <mat-card-actions>
        <button
          mat-raised-button
          color="accent"
          [routerLink]="['/account']"
          (click)="submit()"
        >
          <mat-icon>save</mat-icon>
          Save
        </button>
      </mat-card-actions>
    </mat-card>
    <!-- <mat-card class="col-md-5">
      <pre>
    {{ account | json }} 
  </pre
      >
    </mat-card> -->
  </div>
</div>
