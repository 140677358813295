import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { Expert } from '../models/expert';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-expert-add',
  templateUrl: './expert-add.component.html',
  styleUrls: ['./expert-add.component.scss'],
})
export class ExpertAddComponent implements OnInit {
  expert: Expert = {
    title: '',
    description: '',
    email: '',
    name: '',
    photoURL: '',
  };
  @Input() experts: Expert[] = [];
  @Output() expertsChanged = new EventEmitter<Expert[]>();
  constructor(
    public dialogRef: MatDialogRef<ExpertAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Expert
  ) {
    if (data) {
      this.expert = data;
    }
  }

  ngOnInit(): void {}

  saveExpert() {
    this.dialogRef.close(this.expert);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // removeExpert(expert: Expert) {
  //   this.expertsChanged.emit(
  //     [...this.experts].splice(this.experts.indexOf(expert), 1)
  //   );
  // }
}
