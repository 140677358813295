import { User } from 'firebase';

export interface IInvoice {
  id?: string;
  created: Date;
  author: User | string;

  name: string;
  issued: Date;
  dueDate: Date;
  project: string;
  account: string;
  price: number;
  status: INVOICE_STATUS;
  url: string;
  currency: CURRENCY;
}

export enum CURRENCY {
  USD = 'USD',
  EURO = 'EUR',
}

export enum INVOICE_STATUS {
  UNPAID = 'unpaid',
  PAID = 'paid',
  PAYMENT_CONFIRMED = 'payment confirmed',
}
