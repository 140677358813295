<div id="login-container">
  <!-- <div class="row">
    <div class="card">
      <div class="card-body">
        <h1 class="text-center">Welcome to SuperScale Portal!</h1>
      </div>
    </div>
  </div> -->
  <!-- <div class="row justify-content-center"> -->
  <div id="logok"></div>
  <div class="col-md-4 align-self-center">
    <div class="card">
      <div class="align-self-center"></div>
      <div class="card-body">
        <div *ngIf="auth.user$ | async; then authenticated; else guest"></div>

        <!-- User NOT logged in -->
        <ng-template #guest>    
          <app-email-login></app-email-login>
          <hr />
          <p class="text-center">OR</p>
          <p class="text-center">
            <button mat-stroked-button appGoogleSignin>
              <!-- <mat-icon aria-hidden="false">login</mat-icon> -->
              <img height="32" src="/assets/img/g-logo.png" />
              Sign in with Google
            </button>
          </p>
        </ng-template>
        
        <!-- User logged in -->
        <ng-template #authenticated>
          <div *ngIf="auth.user$ | async as user">
            <h3 class="text-center">
              Hello, {{ user.displayName }}, you have been successfully logged
              in!
            </h3>
            <!-- <img class="rounded" width="100" [src]="user.photoURL" /> -->
            <!-- <p>UID: {{ user.email }}</p> -->

            <!-- <button
                    mat-raised-button
                    color="primary"
                    routerLink="/dashboard"
                  >
                    <mat-icon>dashboard</mat-icon>
                    Dashboard
                  </button>
                  <button
                    mat-raised-button
                    color="primary"
                    routerLink="/profile"
                  >
                    <mat-icon>account_circle</mat-icon>
                    Profile
                  </button>
                  <br />
                  <button
                    mat-raised-button
                    color="primary"
                    routerLink="/status"
                  >
                    <mat-icon>notification_important</mat-icon>
                    Notification
                  </button>
                  <br />
                  <br />
                  <br />
                  <br />
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="auth.signOut()"
                  >
                    <mat-icon>logout</mat-icon>
                    Log out
                  </button> -->
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
