export const environment = {
  firebase: {
    apiKey: 'AIzaSyAOO0JbGOAbGlcqYyKNZAPQjOzhAfaGqBA',
    authDomain: 'superscale-portal.firebaseapp.com',
    databaseURL: 'https://superscale-portal.firebaseio.com',
    projectId: 'superscale-portal',
    storageBucket: 'superscale-portal.appspot.com',
    messagingSenderId: '15722045405',
    appId: '1:15722045405:web:fe0def8f7a8176727ba034',
    measurementId: 'G-EF05G3VFSQ',
  },
  production: true
};
