import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAccess } from '../models/access';
import { IInvoice } from '../models/invoice';
import { AuthService } from './auth.service';
import { transformFirebase } from './helpers';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {
  public invoicesMapAccounts: {
    [accountId: string]: Observable<IInvoice[]>;
  } = {};
  public invoicesMapProjects: {
    [projectId: string]: Observable<IInvoice[]>;
  } = {};

  constructor(private afs: AngularFirestore, private afAuth: AuthService) {}

  createInvoice({
    account,
    name,
    created,
    id,
    issued,
    price,
    currency,
    status,
    author,
    project,
    dueDate,
    url,
  }: IInvoice) {
    console.log(
      account,
      name,
      created,
      id,
      issued,
      price,
      status,
      url,
      author,
      account,
      project,
      dueDate,
      currency
    );
    this.afs
      .collection<IInvoice>(`invoices`)
      .add({
        ...(id && { id: id }),
        ...(account && { account: account }),
        ...(name && { name: name }),
        ...(created && { created: created }),
        ...(issued && { issued: issued }),
        ...(price && { price: price }),
        ...(currency && { currency: currency }),
        ...(status && { status: status }),
        ...(url && { url: url }),
        ...(author && { author: author }),
        ...{ project: project || '' },
        ...(dueDate && { dueDate: dueDate }),
      })
      .then(() => {
        console.log('pls');
      })
      .catch(alert);
  }

  updateInvoice({
    account,
    name,
    created,
    id,
    issued,
    price,
    currency,
    status,
    url,
    author,
    project,
    dueDate,
  }: IInvoice) {
    this.afs
      .doc(`invoices/${id}`)
      .set(
        {
          ...(id && { id: id }),
          ...(account && { account: account }),
          ...(name && { name: name }),
          ...(created && { created: created }),
          ...(issued && { issued: issued }),
          ...(price && { price: price }),
          ...(currency && { currency: currency }),
          ...(status && { status: status }),
          ...(url && { url: url }),
          ...(author && { author: author }),
          ...(project && { project: project }),
          ...(dueDate && { dueDate: dueDate }),
        },
        {
          merge: true,
        }
      )
      .then(() => {
        console.log('yes', id, status);
      })
      .catch(alert);
  }

  getInvoice$(id: string): Observable<IInvoice> {
    return this.afs
      .doc<IInvoice>(`invoices/${id}`)
      .valueChanges()
      .pipe(
        map((q) => transformFirebase<IInvoice>(q)),
        map((invoice) => {
          return { id: id, ...invoice };
        })
      );
  }

  getInvoicesForAccess$(access: IAccess) {
    if (access.project) {
      return this.getInvoicesForProject$(access.project);
    }
    return this.getInvoicesForProject$(access.project);
  }

  getInvoicesPerAccesses$(accesses: IAccess[]) {
    return accesses.map((access) => {
      return this.getInvoicesForAccess$(access)
     })
  }

  getInvoicesForProject$(projectId: string): Observable<IInvoice[]> {
    console.log(projectId, 'KKKKKKKKKKKKKKKKKKKKKKKKKK');
    if (!(projectId in this.invoicesMapProjects)) {
      this.invoicesMapProjects[projectId] = this.afs
        .collection<IInvoice>('invoices', (ref) =>
          ref.where('project', '==', projectId).orderBy('issued')
        )
        .valueChanges({ idField: 'id' })
        .pipe(map((q) => q.map((item) => transformFirebase<any>(item))));
    }
    return this.invoicesMapProjects[projectId];
  }

  getInvoicesForAccount$(accountId: string): Observable<IInvoice[]> {
    console.log(accountId, 'ASDGFGHFGFGF');
    if (!(accountId in this.invoicesMapAccounts)) {
      this.invoicesMapAccounts[accountId] = this.afs
        .collection<IInvoice>('invoices', (ref) =>
          ref
            .where('account', '==', accountId)
            .where('project', '==', '')
            .orderBy('issued')
        )
        .valueChanges({ idField: 'id' })
        .pipe(map((q) => q.map((item) => transformFirebase<any>(item))));
    }
    return this.invoicesMapAccounts[accountId];
  }

  deleteInvoice(invoice: IInvoice) {
    this.afs
      .doc(`invoices/${invoice.id}`)
      .delete()
      .then(() => {
        console.log('yess', invoice.id);
      })
      .catch((err) => console.log('err', err));
  }
}
