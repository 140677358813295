<br />
<br />

<div class="container" *ngIf="user">
  <div class="row">
    <div class="col-md-12">
      <div class="card card-profile">
        <div class="card-avatar">
          <img class="img" [src]="avatar" />
        </div>

        <div class="card-header card-header-danger">
          <h4 class="card-title">{{ user.displayName }}</h4>
          <p class="card-category">Get yourself known</p>
        </div>
        <div class="card-body">
          <form>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Name"
                    [(ngModel)]="user.displayName"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    disabled
                    [value]="user.email"
                    placeholder="Email address"
                    type="email"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Position (your role in company)"
                    [(ngModel)]="user.position"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Slack Handle"
                    [(ngModel)]="user.slack"
                    [ngModelOptions]="{ standalone: true }"
                    type="text"
                  />
                </mat-form-field>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-md-4">
                    <mat-form-field appearance="outline">
                      <input matInput placeholder="City" type="text" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="outline">
                      <input matInput placeholder="Country" type="text" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="outline">
                      <input matInput placeholder="Postal Code" type="text" />
                    </mat-form-field>
                  </div>
            </div> -->
            <div class="row">
              <div class="col-md-12">
                <label>About Me</label>
                <mat-form-field appearance="outline">
                  <textarea
                    [(ngModel)]="user.bio"
                    [ngModelOptions]="{ standalone: true }"
                    matInput
                    placeholder="Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo."
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
            <button
              mat-raised-button
              type="submit"
              class="btn btn-danger pull-right"
              (click)="submit()"
            >
              Update Profile
            </button>
            <div class="clearfix"></div>
          </form>
        </div>
      </div>
    </div>

    <!-- <div class="row">
    <a class="btn btn-danger btn-round" (click)="auth.signOut()">Log Out</a>
  </div> -->
  </div>
</div>
