<div class="container">
  <div class="row">
    <div class="card">
      <div class="card-body">
        <h1 class="text-center">Legal &amp; Documents</h1>
      </div>
    </div>
  </div>
  <ng-container *ngIf="res | async as groups">
    <div *ngFor="let group of groups; index as i" class="col-lg-12 col-md-12">
      <div class="card">
        <div class="card-header card-header-warning">
          <h4 class="card-title">
            <mat-icon>text_snippet</mat-icon>
            {{ group.name | async }}
          </h4>
        </div>
        <div class="card-body table-responsive">
          <table class="table table-hover">
            <thead class="text-warning">
              <th>Date Uploaded</th>
              <th>Type</th>
              <th>Status</th>
              <th>Name</th>
              <!-- <th>Info</th> -->
              <th>Start Date</th>
              <th>End Date</th>
              <th>Uploaded By</th>
              <th *ngIf="!this.auth.isSuperAdmin"></th>
              <th *ngIf="this.auth.isSuperAdmin" colspan="3"></th>
            </thead>
            <tbody>
              <tr
                *ngFor="let document of group.legals | async"
                [class.warn]="document.isWarning()"
              >
                <td>{{ document.created | date }}</td>
                <td>{{ document.type }}</td>
                <td>
                  {{ document.legalStatus }}
                </td>
                <td>
                  {{ document.name }} -
                  <span [ngClass]="document.isActive() ? 'active' : 'inactive'"
                    ><b>{{ document.isActive() ? "ACTIVE" : "INACTIVE" }}</b>
                  </span>
                </td>
                <!-- <td><div [innerHTML]="document.description"></div></td> -->
                <td>{{ document.startDate | date }}</td>
                <ng-container
                  *ngIf="document.hasTerminationDate; else noTermination"
                >
                  <td matTooltip="{{ document.remainingActiveDays() }}">
                    {{ document.endDate | date }} <br />({{
                      document.remainingActiveDays()
                    }})
                  </td>
                </ng-container>
                <ng-template #noTermination>
                  <td matTooltip="No termination date set">Not set</td>
                </ng-template>

                <td>{{ document.authorName }}</td>
                <td>
                  <a
                    mat-raised-button
                    [href]="document.url"
                    target="_blank"
                    rel="noopener"
                    color="accent"
                  >
                    <mat-icon>cloud_download</mat-icon>
                    Download
                  </a>
                </td>
                <ng-container *ngIf="this.auth.isSuperAdmin">
                  <td>
                    <a
                      mat-mini-fab
                      color="primary"
                      (click)="editLegal(document)"
                    >
                      <mat-icon>edit</mat-icon>
                      <!-- Edit -->
                    </a>
                  </td>
                  <td>
                    <a mat-mini-fab color="warn" (click)="remove(document)">
                      <mat-icon>delete</mat-icon>
                    </a>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- </div>
    </div>
     -->
    </div>
  </ng-container>
  <mat-card *ngIf="auth.isSuperAdmin">
    <mat-card-header>
      <mat-card-title>SuperAdmin Section</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <button color="accent" mat-raised-button [routerLink]="['../legals-add']">
        <mat-icon>add</mat-icon>
        Add Document
      </button>
    </mat-card-content>
  </mat-card>
</div>
