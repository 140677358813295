<h1>
    Please finish setup
  </h1>
  
<div class="row">    
    <div class="col-md-12">
      <button mat-raised-button (click)="isOptional = !isOptional">
        {{ !isOptional ? "Enable optional steps" : "Disable optional steps" }}
      </button>

      <mat-vertical-stepper linear #stepper>
        <mat-step [stepControl]="firstFormGroup">
          <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>Fill out your name</ng-template>
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input
                matInput
                placeholder="Last name, First name"
                formControlName="firstCtrl"
                required
              />
            </mat-form-field>
            <ng-template matStepLabel>Fill out your name</ng-template>
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input
                matInput
                placeholder="Last name, First name"
                formControlName="firstCtrl"
                required
              />
            </mat-form-field>
            <ng-template matStepLabel>Fill out your name</ng-template>
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input
                matInput
                placeholder="Last name, First name"
                formControlName="firstCtrl"
                required
              />
            </mat-form-field>
            <ng-template matStepLabel>Fill out your name</ng-template>
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input
                matInput
                placeholder="Last name, First name"
                formControlName="firstCtrl"
                required
              />
            </mat-form-field>
            <ng-template matStepLabel>Fill out your name</ng-template>
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input
                matInput
                placeholder="Last name, First name"
                formControlName="firstCtrl"
                required
              />
            </mat-form-field>
            <div>
              <button mat-button matStepperNext>Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" [optional]="isOptional">
          <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>Fill out your address</ng-template>
            <mat-form-field>
              <mat-label>Address</mat-label>
              <input
                matInput
                formControlName="secondCtrl"
                placeholder="Ex. 1 Main St, New York, NY"
                required
              />
            </mat-form-field>
            <div>
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button matStepperNext>Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Done</ng-template>
          <p>You are now done.</p>
          <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button (click)="stepper.reset()">Reset</button>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </div>