import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import {
  switchMap,
  map,
  tap,
  takeUntil,
  filter,
  shareReplay,
} from 'rxjs/operators';
import { IUser, USER_ROLES } from '../models/user';
import { UserService } from './user.service';
import { ACCESS_TYPE, IAccess, getAccessId } from '../models/access';
import { AccessService } from './accesses.service';
import { IProject } from '../models/project';
import { IAccount } from '../models/account';
import { ProjectService } from './project.service';
import { AccountService } from './account.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  user: IUser;
  user$: Observable<IUser>;
  email: string;
  myAccesses$: Observable<IAccess[]> = of(null);
  _accesses: IAccess[] = [];
  myAccessesMap$: Observable<{ [accountId: string]: string[] }> = of({});
  isLoggedIn = false;
  activeProject: Observable<IProject> = of(null);
  activeAccount: Observable<IAccount> = of(null);
  activeProjectId: string;
  activeAccountId: string;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private userService: UserService,
    private accessService: AccessService,
    // private activateRoute: ActivatedRoute,
    // private projectService: ProjectService,
    // private accountService: AccountService
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          // console.log('asdfs', user)
          this.email = user.email;
          this.isLoggedIn = true;
          return afs.doc<IUser>(`users/${user.email}`).valueChanges();
        } else {
          this.email = null;
          this.user = null;
          this.isLoggedIn = false;
          return of(null);
        }
      })
    );

    this.myAccesses$ = this.user$.pipe(
      filter((user) => !!user),
      // tap((a) => console.log('accc for user', a)),
      switchMap((user) => {
        return this.accessService.getAccessesForUser(user.email);
      }),
      shareReplay(1)
    );

    this.myAccessesMap$ = this.myAccesses$.pipe(
      switchMap((accs: IAccess[]) => {
        let accessMap = {};
        accs.forEach((access) => {
          if (!(access.account in accessMap)) {
            accessMap[access.account] = [];
          }
          if (!!access.project) accessMap[access.account].push(access.project);
        });
        return of(accessMap);
      })
    );

    this.myAccesses$.subscribe((accs) => {
      console.log('subs acc', accs);
      this._accesses = accs;
    });

    this.user$.subscribe((user) => {
      this.user = user;
      // alert(user?.email || 'nic')
      // console.log(user)
    });
  }

  hasAnyRole(roles: USER_ROLES[]): boolean {
    roles.forEach((role) => {
      if (this.user?.roles?.indexOf(role) > -1) {
        return true;
      }
    });
    return true;
  }

  hasRole(role: USER_ROLES): boolean {
    return this.user?.roles?.indexOf(role) > -1;
  }

  getAccess$(accountId: string, projectId: string): Observable<IAccess> {
    return this.myAccesses$.pipe(
      switchMap((accesses) => {
        return of(
          accesses.find(
            (a) => a.id === getAccessId(accountId, projectId, this.email)
          )
        );
      })
    );
  }

  hasAccess(
    accessType: ACCESS_TYPE,
    accountId: string,
    projectId: string
  ): boolean {
    return (
      this.isSuperAdmin ||
      this.isAdmin(accountId, projectId) ||
      !!this._accesses.find((access) => {
        // console.log(access, getAccessId(accountId, projectId, this.email));

        return (
          getAccessId(accountId, projectId, this.email) === access.id &&
          (accessType === ACCESS_TYPE.access || access[accessType] === true)
        );
      })
    );
  }

  get myAccountIds(): string[] {
    return [...new Set(this._accesses.map((a) => a.account))];
  }

  get myProjectIds(): string[] {
    return [...new Set(this._accesses.map((a) => a.project))];
  }

  get isSuperAdmin(): boolean {
    return (
      this.user?.roles?.indexOf(USER_ROLES.SUPERADMIN) > -1 ||
      this.email === 'peter.sulik@superscale.com'
    );
  }

  isAdmin(accountId: string, projectId = null): boolean {
    if (projectId) {
      return !!this._accesses.find(
        (a) => a.admin && a.account === accountId && a.project === projectId
      );
    }
    return !!this._accesses.find((a) => a.admin && a.account === accountId);
  }

  get isLoggedIn$(): Observable<boolean> {
    return this.user$.pipe(
      // tap(console.log),
      map((u) => {
        console.log(!!u, u);
        return !!u;
      })
    );
  }

  async googleSignin() {
    const provider = new auth.GoogleAuthProvider();
    const credentials = await this.afAuth.signInWithPopup(provider);
    console.log(credentials);
    if (credentials?.additionalUserInfo?.isNewUser) {
      this.updateUserFromCredetentials(credentials);
    } else {
      console.log('neupdatujeeem!');
    }
    this.redirectToAvailableProject();
  }

  async emailSignIn(email: string, password: string) {
    const credentials = await this.afAuth.signInWithEmailAndPassword(
      email,
      password
    );
    this.redirectToAvailableProject();
  }

  updateUserFromCredetentials(credentials: auth.UserCredential) {
    this.userService.updateUser({
      displayName:
        credentials.user.displayName || credentials.user.email.split('@')[0],
      email: credentials.user.email,
      photoURL: credentials.user.photoURL,
      position: undefined,
      roles: undefined,
      name: undefined,
      accounts: undefined,
      projects: undefined,
      bio: undefined,
      slack: undefined,
    });
  }

  redirectToAvailableProject() {
    this.myAccesses$.subscribe((accs) => {
      let url = ['/account'];
      const access: IAccess =
        accs.find((acc) => acc.project) ??
        accs.find((acc) => acc.account) ??
        null;
      if (access) {
        url.push(access.account);
        if (access.project) url = url.concat(['project', access.project]);
      } else {
        // TODO REDIRECT TO PAGE WHERE USER HAS NO ACCOUNTS NOR ANYTHING
        // possibly marketing
      }
      this.router.navigate(url);
    });
  }

  async emailRegister(email: string, password: string) {
    const credentials = await this.afAuth.createUserWithEmailAndPassword(
      email,
      password
    );
    this.updateUserFromCredetentials(credentials);
  }

  async passwordReset(email: string) {
    return await this.afAuth.sendPasswordResetEmail(email);
  }

  public async signOut() {
    await this.afAuth.signOut();
    this.router.navigate(['/login']);
  }
}
