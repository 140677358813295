import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  AngularFireStorage,
  AngularFireUploadTask,
  AngularFireStorageReference,
} from '@angular/fire/storage';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { FilesService, FILE_TYPE } from '../services/files.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'uploader-task',
  templateUrl: './uploader-task.component.html',
  styleUrls: ['./uploader-task.component.scss'],
})
export class UploaderTaskComponent implements OnInit {
  @Input() file: File;
  @Output() fileUrl = new EventEmitter<string>();
  @Input() type: FILE_TYPE = FILE_TYPE.MEDIA;
  @Input() account = '';
  @Input() project = '';
  @Input() showDelete = false;
  // task: AngularFireUploadTask;
  deleted = false;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  storageRef: AngularFireStorageReference;
  downloadURL: string;

  constructor(
    private filesService: FilesService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    const acid = this.activatedRoute.snapshot.paramMap.get('accountId');
    const projid = this.activatedRoute.snapshot.paramMap.get('projectId');
    this.account = !!this.account ? this.account : acid;
    this.project = !!this.project ? this.project : projid;
    this.startUpload();
  }

  async startUpload() {
    const { percentage, snapshot, storageRef } = this.filesService.uploadFile(
      this.file,
      this.project,
      this.account,
      this.type
    );
    this.storageRef = storageRef;
    this.percentage = percentage;
    this.snapshot = snapshot.pipe(
      finalize(async () => {
        const url = await this.storageRef.getDownloadURL().toPromise();
        this.downloadURL = url;
        this.fileUrl.emit(url);
      })
    );
  }

  async delete() {
    alert(`This will delete this file ${this.downloadURL}`);
    const res = await this.filesService.deleteFileByDownloadURL(this.downloadURL);
    if(res) {
      this.deleted = true;
    }
  }

  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }
}
