<div *ngIf="activeProject$ | async as activeProject; else loading">
  <div
    class="hero-image"
    [ngStyle]="{
      'background-image':
        'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(' +
        activeProject.logo +
        ')'
    }"
  >
    <div class="hero-text">
      <h1>
        {{ activeProject?.name }}
      </h1>
      <p>{{ (activeAccount$ | async)?.name | uppercase }}</p>
      <!-- {{ activeProject.logo }} -->
    </div>
  </div>
  <div class="container">
    <div class="row" *ngIf="auth.isSuperAdmin">
      <div class="card">
        <div class="card-body">
          <h1 class="text-center">SuperAdmin Section</h1>
          <button
            mat-flat-button
            color="primary"
            matTooltip="Edit Project"
            [matTooltipPosition]="'above'"
            [routerLink]="['../../../project-add', projectId]"
          >
            <i class="material-icons">edit</i>
            Edit Project
          </button>
          <button
            mat-flat-button
            color="primary"
            matTooltip="Add invoice"
            [matTooltipPosition]="'above'"
            [routerLink]="['../invoices-add']"
          >
            <i class="material-icons">receipt_long</i>
            Add invoice
          </button>
          <button
            mat-flat-button
            color="primary"
            matTooltip="Add Legal"
            [matTooltipPosition]="'above'"
            [routerLink]="['../legals-add']"
          >
            <i class="material-icons">text_snippet</i>
            Add Legal
          </button>
          <button
            mat-flat-button
            color="primary"
            matTooltip="Add Deliverable"
            [matTooltipPosition]="'above'"
            [routerLink]="['../deliverables-add']"
          >
            <i class="material-icons">picture_as_pdf</i>
            Add Deliverable
          </button>
          <button
            mat-flat-button
            color="primary"
            matTooltip="Add Dashboard"
            [matTooltipPosition]="'above'"
            [routerLink]="['../dashboards-add']"
          >
            <i class="material-icons">analytics</i>
            Add Dashboard
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="isUnpaidInvoice" class="alert alert-warning alert-with-icon">
      <b>Unpaid invoice</b>
      <i class="material-icons" data-notify="icon">report</i>
      <button
        mat-button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <i class="material-icons">close</i>
      </button>
      <span data-notify="message">
        It seems that there is an invoice that has been marked as paid but the
        money was not received yet on our accounts.
      </span>
      <br />
      <p>
        <button
          mat-flat-button
          matTooltip="Here you can see all your Invoices"
          [matTooltipPosition]="'above'"
          [routerLink]="['../invoices']"
        >
          <i class="material-icons">arrow_right</i>
          Go to Invoices
        </button>
      </p>
    </div>

    <div *ngIf="isLegalWarning" class="alert alert-warning alert-with-icon">
      <b>Important documents need your attention</b>
      <i class="material-icons" data-notify="icon">text_snippet</i>
      <button
        mat-button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <i class="material-icons">close</i>
      </button>
      <span data-notify="message">
        It seems that one of legal documents is close to it's termination date.
        Please contact us for more information.
      </span>
      <br />
      <p>
        <button
          mat-flat-button
          matTooltip="Legal documents such as mNDAs, proposals..."
          [matTooltipPosition]="'above'"
          [routerLink]="['../legals']"
        >
          <i class="material-icons">arrow_right</i>
          Go to Legal
        </button>
      </p>
    </div>
    <pre></pre>
    <div class="row" *ngIf="!isOnboardingDone">
      <div class="card">
        <div class="card-body">
          <h1 class="text-center">
            <mat-icon inline="true">assignment</mat-icon><br />
            Please complete these Onboarding Steps
          </h1>
          <div
            class="steps-group"
            *ngFor="let group of activeProject.onboardingGroups"
          >
            <h3>
              {{ group.name }}
            </h3>
            <ul>
              <li *ngFor="let step of group.steps">
                <div [ngClass]="{ done: step.done }">
                  <mat-icon inline="true" *ngIf="step.done">
                    check_circle
                  </mat-icon>
                  <mat-icon inline="true" *ngIf="!step.done"
                    >stop_circle</mat-icon
                  >
                  <span [innerHTML]="step.name | urlsToHrefs"> </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="lastDeliverable$ | async as lastDeliverable">
      <div class="card-header card-header-success">
        <h3 class="card-title">Last update in Delivery</h3>
        <!-- <p class="card-category">
        What's new happening
      </p> -->
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <app-deliverable-card
              [deliverable]="lastDeliverable"
            ></app-deliverable-card>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="places-buttons">
              <div class="col-md-6 ml-auto mr-auto text-center">
                <h4 class="card-title">
                  Past updates in Weekly Updates &amp; Reports
                  <p>
                    <button
                      color="primary"
                      mat-flat-button
                      matTooltip="Invoices"
                      [matTooltipPosition]="'above'"
                      [routerLink]="['../deliverables']"
                    >
                      <i class="material-icons">picture_as_pdf</i>
                      Weekly Updates &amp; Reports
                    </button>
                  </p>
                  <!-- <p class="category">Click to view presentations</p> -->
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-md-4">
        <div class="card card-chart">
          <div class="card-header card-header-success">
            <div class="ct-chart" id="dailySalesChart"></div>
          </div>
          <div class="card-body">
            <h4 class="card-title">Daily Sales</h4>
            <p class="card-category">
              <span class="text-success"
                ><i class="fa fa-long-arrow-up"></i> 55%
              </span>
              increase in today sales.
            </p>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">access_time</i> updated 4 minutes ago
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-chart">
          <div class="card-header card-header-warning">
            <div class="ct-chart" id="websiteViewsChart"></div>
          </div>
          <div class="card-body">
            <h4 class="card-title">Email Subscriptions</h4>
            <p class="card-category">Last Campaign Performance</p>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">access_time</i> campaign sent 2 days ago
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-chart">
          <div class="card-header card-header-danger">
            <div class="ct-chart" id="completedTasksChart"></div>
          </div>
          <div class="card-body">
            <h4 class="card-title">Completed Tasks</h4>
            <p class="card-category">Last Campaign Performance</p>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">access_time</i> campaign sent 2 days ago
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row">
      <!--   <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-header card-header-tabs card-header-primary">
            <div class="nav-tabs-navigation">
              <div class="nav-tabs-wrapper">
                <span class="nav-tabs-title">Deliverables</span>
                <ul class="nav nav-tabs" data-tabs="tabs">
                  <li class="nav-item">
                    <a
                      mat-button
                      class="nav-link active"
                      href="#profile"
                      data-toggle="tab"
                    >
                      <i class="material-icons">picture_as_pdf</i> Weekly
                      updates
                      <div class="ripple-container"></div>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      mat-button
                      class="nav-link"
                      href="#messages"
                      data-toggle="tab"
                    >
                      <i class="material-icons">code</i> Website
                      <div class="ripple-container"></div>
                    </a>
                  </li> 
                 <li class="nav-item">
                    <a
                      mat-button
                      class="nav-link"
                      href="#settings"
                      data-toggle="tab"
                    >
                      <i class="material-icons">cloud</i> Server
                      <div class="ripple-container"></div>
                    </a>
                  </li> 
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="tab-content">
              <div class="tab-pane active" id="profile">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              checked
                            />
                            <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td>
                        Sign contract for "What are conference organizers afraid
                        of?"
                      </td>
                      <td class="td-actions text-right">
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Edit Task"
                          [matTooltipPosition]="'above'"
                          class="btn btn-primary btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">edit</i>
                        </button>
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Remove"
                          [matTooltipPosition]="'above'"
                          class="btn btn-danger btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">close</i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                            />
                            <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td>
                        Lines From Great Russian Literature? Or E-mails From My
                        Boss?
                      </td>
                      <td class="td-actions text-right">
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Edit Task"
                          [matTooltipPosition]="'above'"
                          class="btn btn-primary btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">edit</i>
                        </button>
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Remove"
                          [matTooltipPosition]="'above'"
                          class="btn btn-danger btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">close</i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                            />
                            <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td>
                        Flooded: One year later, assessing what was lost and
                        what was found when a ravaging rain swept through metro
                        Detroit
                      </td>
                      <td class="td-actions text-right">
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Edit Task"
                          [matTooltipPosition]="'above'"
                          class="btn btn-primary btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">edit</i>
                        </button>
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Remove"
                          [matTooltipPosition]="'above'"
                          class="btn btn-danger btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">close</i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              checked
                            />
                            <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td>
                        Create 4 Invisible User Experiences you Never Knew About
                      </td>
                      <td class="td-actions text-right">
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Edit Task"
                          [matTooltipPosition]="'above'"
                          class="btn btn-primary btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">edit</i>
                        </button>
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Remove"
                          [matTooltipPosition]="'above'"
                          class="btn btn-danger btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">close</i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="tab-pane" id="messages">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              checked
                            />
                            <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td>
                        Flooded: One year later, assessing what was lost and
                        what was found when a ravaging rain swept through metro
                        Detroit
                      </td>
                      <td class="td-actions text-right">
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Edit Task"
                          [matTooltipPosition]="'above'"
                          class="btn btn-primary btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">edit</i>
                        </button>
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Remove"
                          [matTooltipPosition]="'above'"
                          class="btn btn-danger btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">close</i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                            />
                            <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td>
                        Sign contract for "What are conference organizers afraid
                        of?"
                      </td>
                      <td class="td-actions text-right">
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Edit Task"
                          [matTooltipPosition]="'above'"
                          class="btn btn-primary btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">edit</i>
                        </button>
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Remove"
                          [matTooltipPosition]="'above'"
                          class="btn btn-danger btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">close</i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="tab-pane" id="settings">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                            />
                            <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td>
                        Lines From Great Russian Literature? Or E-mails From My
                        Boss?
                      </td>
                      <td class="td-actions text-right">
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Edit Task"
                          [matTooltipPosition]="'above'"
                          class="btn btn-primary btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">edit</i>
                        </button>
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Remove"
                          [matTooltipPosition]="'above'"
                          class="btn btn-danger btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">close</i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              checked
                            />
                            <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td>
                        Flooded: One year later, assessing what was lost and
                        what was found when a ravaging rain swept through metro
                        Detroit
                      </td>
                      <td class="td-actions text-right">
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Edit Task"
                          [matTooltipPosition]="'above'"
                          class="btn btn-primary btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">edit</i>
                        </button>
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Remove"
                          [matTooltipPosition]="'above'"
                          class="btn btn-danger btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">close</i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              checked
                            />
                            <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td>
                        Sign contract for "What are conference organizers afraid
                        of?"
                      </td>
                      <td class="td-actions text-right">
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Edit Task"
                          [matTooltipPosition]="'above'"
                          class="btn btn-primary btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">edit</i>
                        </button>
                        <button
                          mat-raised-button
                          type="button"
                          matTooltip="Remove"
                          [matTooltipPosition]="'above'"
                          class="btn btn-danger btn-link btn-sm btn-just-icon"
                        >
                          <i class="material-icons">close</i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>-->

      <div class="col-md-12" *ngIf="experts && experts.length > 0">
        <app-expert-list [experts]="experts"></app-expert-list>
      </div>
    </div>
  </div>
</div>
<ng-template #loading> Loading... </ng-template>
