import { Component, OnInit, Input } from '@angular/core';
import { DeliverableService } from '../services/deliverable.service';
import { IDeliverable } from '../models/deliverable';

@Component({
  selector: 'app-deliverable-card',
  templateUrl: './deliverable-card.component.html',
  styleUrls: ['./deliverable-card.component.scss'],
})
export class DeliverableCardComponent implements OnInit {
  @Input() deliverable: IDeliverable = null;
  @Input() showAdminControls: boolean = false;

  constructor(public deliverablesService: DeliverableService) {}

  ngOnInit(): void {}

  remove(deliverable: IDeliverable) {
    this.deliverablesService.deleteDeliverable(deliverable);
  }
}
