import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../services/user.service';
import { Subscription, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { IUser } from '../models/user';
import { AccountService } from '../services/account.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { IAccount } from '../models/account';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css'],
})
export class InfoComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  user: IUser;
  account: IAccount;
  constructor(
    public userService: UserService,
    public auth: AuthService,
    public accountService: AccountService,
    activatedRoute: ActivatedRoute
  ) {
    const accountId = activatedRoute.snapshot.paramMap.get('accountId');
    this.subscription.add(
      accountService
        .getMyAccounts$()
        .subscribe(
          (accounts) =>
            (this.account = accounts.find((a) => a.id === accountId))
        )
    );
    // this.subscription.add(
    //   auth.user$.subscribe((user) => {
    //     console.log(user);
    //     this.user = user;
    //   })
    // );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
