import { Injectable } from '@angular/core';
import { ProjectService } from './project.service';
import { AccountService } from './account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { map, switchMap, shareReplay } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { IProject } from '../models/project';
import { IAccount } from '../models/account';

@Injectable({
  providedIn: 'root',
})
export class UserStateService {
  activeProjectId$: Observable<string> = of('');
  activeAccountId$: Observable<string> = of('');
  activeScopeName$: Observable<string> = of('');
  activeAccount$: Observable<IAccount> = of(null);
  activeProject$: Observable<IProject> = of(null);

  constructor(
    projectService: ProjectService,
    accountService: AccountService,
    activatedRoute: ActivatedRoute,
    router: Router
  ) {
    // this.router.routerState.root.firstChild.params['value']['projectId'] ||
    // const params = activatedRoute.firstChild.paramMap.pipe(
    const params = router.routerState.root.firstChild.paramMap.pipe(
      map((params) => {
        const res = {
          projectId: params.get('projectId') || '',
          accountId: params.get('accountId') || '',
        }; 
        console.log(res, '_______________________');
        return res;
      })
    );

    this.activeProjectId$ = params.pipe(map((params) => params.projectId));
    this.activeAccountId$ = params.pipe(map((params) => params.accountId));
    this.activeScopeName$ = params.pipe(
      switchMap((params) => {
        // console.log(params.projectId, params.accountId, '_______________');
        return params.projectId
          ? projectService.getProject$(params.projectId)
          : params.accountId
          ? accountService.getAccount$(params.accountId)
          : of(null);
      }),
      map((scope) => {
        return (scope && scope.name) || 'nothin';
      }),
      // shareReplay(1)
    );

    this.activeProject$ = params.pipe(
      switchMap((params) =>
        params.projectId
          ? projectService.getProject$(params.projectId)
          : of(null)
      )
    );
  }
}
