import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { FILE_TYPE } from '../services/files.service';

@Component({
  selector: 'app-media-editor',
  templateUrl: './media-editor.component.html',
  styleUrls: ['./media-editor.component.scss'],
})
export class MediaEditorComponent implements OnInit {
  @Input() text = '';
  @Output() textChange = new EventEmitter();
  @ViewChild('editor') public editor;
  fileType = FILE_TYPE.MEDIA;

  modelChanged($event: any) {
    this.text = $event;
    this.textChange.emit(this.text);
  }

  constructor() {}

  mediaUploaded(url: string) {
    const extension = url.split('.').pop().split('?')[0].toLowerCase();
    let link = `<a href="${url}">LINK</a>`;
    if (['png', 'jpeg', 'jpg', 'gif', 'svg'].indexOf(extension) !== -1) {
      link = `<img src="${url}"/ width="300">`;
    }
    this.text = this.text + link;
    // this.editor.editor.execCommand('mceInsertContent', false, link);
  }

  ngOnInit(): void {}
}
