import { Component, OnInit } from '@angular/core';
import { Subscription, Observable, of } from 'rxjs';
import { IAccount } from '../models/account';
import { ActivatedRoute } from '@angular/router';
import { IProject } from '../models/project';
import { ProjectService } from '../services/project.service';
import { AccountService } from '../services/account.service';
import { UserService } from '../services/user.service';
import { IUser } from '../models/user';
import { FILE_TYPE } from '../services/files.service';

@Component({
  selector: 'app-accounts-add',
  templateUrl: './account-add.component.html',
  styleUrls: ['./account-add.component.scss'],
})
export class AccountsAddComponent implements OnInit {
  subscription = new Subscription();
  id = '';
  projects$: Observable<IProject[]>;
  users$: Observable<IUser[]>;
  fileType = FILE_TYPE.MEDIA;

  account: IAccount = {
    id: '',
    name: '',
    logo: '',
    projects: [],
    billingInfo: '',
    users: [],

    // created: new Date(),
    // author: new Date(),
  };

  constructor(
    private accountsService: AccountService,
    private projectsService: ProjectService,

    private activatedRoute: ActivatedRoute
  ) {
    this.id = activatedRoute.snapshot.paramMap.get('accountId');
    this.projects$ = projectsService.getMyProjects();
    if (this.id) {
      accountsService.getAccount$(this.id).subscribe((account) => {
        this.account = account;
        console.log(account);
      });
    }
  }

  ngOnInit(): void {}

  submit(): void {
    if (this.id) {
      this.accountsService.updateAccount(this.account);
    } else {
      this.accountsService.createAccount(this.account);
    }
  }

  change(event) {
    console.log(event);
  }

  logoUploaded(url: string) {
    this.account.logo = url;
  }
}
