<div class="container">
  <div class="row" *ngIf="auth.isSuperAdmin">
    <div class="card">
      <div class="card-body">
        <h1 class="text-center">SuperAdmin Section</h1>
        <button
          color="accent"
          mat-raised-button
          [routerLink]="['../dashboards-add']"
        >
          <mat-icon>add</mat-icon>
          Add Dashboard
        </button>
      </div>
    </div>
  </div>
</div>
<mat-tab-group mat-align-tabs="start">
  <mat-tab *ngFor="let view of dashboards$ | async" label="{{ view.name }}">
    <div class="row-container">
      <h1>
        {{ view.name }}
        <a href="{{ view.url }}" target="_blank"
          >(<mat-icon>link</mat-icon> visit)</a
        >
      </h1>
      <p [innerHTML]="view.description"></p>
      <p *ngIf="auth.isSuperAdmin">
        <button
          color="accent"
          mat-raised-button
          [routerLink]="['../dashboards-add', view.id]"
        >
          <mat-icon>edit</mat-icon>
          Edit Dashboard
        </button>
        <button color="warn" mat-raised-button (click)="remove(view)">
          <mat-icon>delete</mat-icon>
          Delete
        </button>
        <br />
      </p>
      <iframe
        class="embeded-view"
        height="{{ view.height ? view.height : 10000 }}"
        [src]="view.url | safe"
        frameborder="0"
        style="border: 0"
        allowfullscreen
      ></iframe>
    </div>
  </mat-tab>
</mat-tab-group>
