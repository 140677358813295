import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FILE_TYPE } from '../services/files.service';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent {
  @Input() title = 'Upload File';
  @Input() fileType = FILE_TYPE.MEDIA;
  @Output() fileDownloadURL = new EventEmitter<string>();
  isHovering: boolean;
  @Input() limit = 1;
  files: File[] = [];  
  @Input() showDelete = false;
  
  uploadedFile(fileUrl: string) {
    this.fileDownloadURL.emit(fileUrl);
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {      
      this.files.push(files.item(i));
    }    
  }
}
