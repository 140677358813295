import { Component, OnInit } from '@angular/core';
import { IDeliverable, DELIVERABLE_TYPE } from 'src/app/models/deliverable';
import { AuthService } from 'src/app/services/auth.service';
import { DeliverableService } from 'src/app/services/deliverable.service';
import { ActivatedRoute } from '@angular/router';
import { FILE_TYPE } from 'src/app/services/files.service';

@Component({
  selector: 'app-deliverable-add',
  templateUrl: './deliverable-add.component.html',
  styleUrls: ['./deliverable-add.component.scss'],
})
export class DeliverableAddComponent implements OnInit {
  public fileType = FILE_TYPE.DELIVERABLE;
  id = '';
  projectId = '';
  accountId = '';
  deliverableTypes = Object.values(DELIVERABLE_TYPE);
  deliverable: IDeliverable = {
    name: '',
    author: null,
    authorName: '',
    created: new Date(),
    description: '',
    type: DELIVERABLE_TYPE.PRESENTATION,
    project: '',
    account: '',
    url: '',
  };
  constructor(
    public auth: AuthService,
    public deliverableService: DeliverableService,
    activatedRoute: ActivatedRoute
  ) {
    this.deliverable.author = auth.email;
    this.deliverable.authorName = auth.user.displayName || auth.user.email;
    this.id = activatedRoute.snapshot.paramMap.get('deliverableId');
    this.projectId = activatedRoute.snapshot.paramMap.get('projectId');
    this.deliverable.project = this.projectId;

    this.accountId = activatedRoute.snapshot.paramMap.get('accountId');
    this.deliverable.account = this.accountId;
    if (this.id) {
      this.deliverableService
        .getDeliverable(this.id)
        .subscribe((deliverable) => {
          this.deliverable = deliverable;
        });
    }
  }

  ngOnInit(): void {}

  submit(): void {
    if (this.id) {
      this.deliverableService.updateDeliverable(this.deliverable);
    } else {
      this.deliverableService.createDeliverable(this.deliverable);
    }
  }

  uploaded(url: string) {
    this.deliverable.url = url;
  }
}
