<div *ngIf="isSignup" class="text-center">
  <h2>Create Account</h2>
  <button mat-stroked-button (click)="changeType('login')">
    I already have an account
  </button>
</div>

<div *ngIf="isPasswordReset">
  <h2 class="text-center">Reset Password</h2>
  <button size="small" mat-button (click)="changeType('login')">Back</button>
</div>

<div *ngIf="isLogin">
  <h2 class="text-center">Login</h2>
  <p class="text-center">Please login to continue...</p>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="outline" [color]="email.valid && 'accent'">
    <input
      matInput
      formControlName="email"
      type="email"
      placeholder="Email"
      autocomplete="off"
    />

    <mat-error *ngIf="email.invalid && email.dirty">
      You must enter a valid email address
    </mat-error>
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    [color]="email.valid && 'accent'"
    *ngIf="!isPasswordReset"
  >
    <input
      matInput
      formControlName="password"
      type="password"
      placeholder="Password"
      autocomplete="off"
    />

    <mat-error *ngIf="password.invalid && password.dirty">
      Password must be at least 8 characters long
    </mat-error>
  </mat-form-field>
  <button
    color="primary"
    mat-button
    *ngIf="isLogin"
    (click)="changeType('reset')"
  >
    Forgot password?
  </button>

  <mat-form-field appearance="outline" *ngIf="isSignup">
    <input
      matInput
      formControlName="passwordConfirm"
      type="password"
      placeholder="Confirm password"
      autocomplete="off"
    />
    <mat-error> Password does not match </mat-error>
  </mat-form-field>
  <p>
    <mat-error class="server-error">{{ serverMessage }}</mat-error>
  </p>

  <button
    *ngIf="isPasswordReset"
    mat-stroked-button
    type="submit"
    [disabled]="loading"
  >
    Send Reset Email
  </button>

  <button
    *ngIf="isSignup"
    mat-raised-button
    color="accent"
    type="submit"
    [disabled]="form.invalid || loading"
  >
    Register
  </button>

  <button
    *ngIf="isLogin"
    mat-raised-button
    color="accent"
    type="submit"
    [disabled]="loading"
  >
    Login
  </button>
</form>

<div *ngIf="isLogin" class="mx-auto text-center">
  <button size="small" mat-stroked-button (click)="changeType('signup')">
    New user?
  </button>
</div>
