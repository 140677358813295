import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../services/user.service';
import { Subscription, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { IUser } from '../models/user';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  avatar = '/assets/img/avatar.png';
  subscription = new Subscription();
  user: IUser = null;
  constructor(
    public userService: UserService,
    public auth: AuthService,
    private _snackBar: MatSnackBar
  ) {
    this.subscription.add(
      auth.user$.subscribe((user) => {
        this.user = user;
        this.avatar = this.user.photoURL ?? this.avatar;
      })
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async submit() {
    await this.userService.updateUser({
      displayName: this.user.displayName,
      email: this.auth.email,
      position: this.user.position,
      bio: this.user.bio,
      slack: this.user.slack,
    } as any);

    this._snackBar.open('Profile Updated!', 'OK', {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }
}
