<ng-container *ngIf="!deleted; else deletedView">
  <div *ngIf="percentage | async as pct">
    <progress [value]="pct" max="100"></progress>
    {{ pct | number }}%
  </div>

  <div *ngIf="snapshot | async as snap">
    {{ snap.bytesTransferred | filesize }} of {{ snap.totalBytes | filesize }}

    <div *ngIf="downloadURL as url">
      <a [href]="url" target="_blank" mat-flat-button rel="noopener"
        ><mat-icon>cloud_download</mat-icon> Download "{{ file.name }}"</a
      >
      <button
        mat-mini-fab
        color="warn"
        *ngIf="showDelete && downloadURL"
        (click)="delete()"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <!-- 
    <button (click)="task.pause()" [disabled]="!isActive(snap)">Pause</button>
    <button (click)="task.cancel()" [disabled]="!isActive(snap)">Cancel</button>
    <button (click)="task.resume()" [disabled]="!(snap?.state === 'paused')">
      Resume
    </button> -->
  </div>
</ng-container>
<ng-template #deletedView>
  <s>DELETED FILE</s>
</ng-template>
<hr />
