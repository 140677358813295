import { Component, OnInit, OnDestroy } from '@angular/core';
import { Expert } from '../models/expert';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { IProject } from '../models/project';
import { switchMap, filter, tap, map } from 'rxjs/operators';
import { ProjectService } from '../services/project.service';
import { DeliverableService } from '../services/deliverable.service';
import { IDeliverable } from '../models/deliverable';
import { AuthService } from '../services/auth.service';
import { InvoicesService } from '../services/invoices.service';
import { INVOICE_STATUS } from '../models/invoice';
import { LegalsService } from '../services/legal.service';
import { ACCESS_TYPE } from '../models/access';
import { LEGAL_TYPE } from '../models/legal';
import { AccountService } from '../services/account.service';
import { IAccount } from '../models/account';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
})
export class ProjectOverviewComponent implements OnInit, OnDestroy {
  activeProject$: Observable<IProject>;
  activeAccount$: Observable<IAccount>;

  isOnboardingDone = false;
  isUnpaidInvoice = false;
  isLegalWarning = false;

  lastDeliverable$: Observable<IDeliverable>;
  projectId = '';
  accountId = '';
  subscription = new Subscription();
  experts: Expert[] = [];

  constructor(
    public projectService: ProjectService,
    public invoicesService: InvoicesService,
    public legalsService: LegalsService,
    public auth: AuthService,
    public deliverablesService: DeliverableService,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService
  ) {
    // TODO UNSUBSCRIBE!!

    this.lastDeliverable$ = this.activatedRoute.paramMap.pipe(
      switchMap((params) => {
        this.projectId = params.get('projectId');
        this.accountId = params.get('accountId');
        if (
          !this.auth.hasAccess(
            ACCESS_TYPE.delivery,
            this.accountId,
            this.projectId
          )
        ) {
          return of(null);
        }
        return this.deliverablesService.getDeliverablesForProject(
          this.projectId
        );
      }),
      tap(console.log),
      switchMap((e) => (e.length && of(e[0])) || of(null))
    );

    this.activeProject$ = this.activatedRoute.paramMap.pipe(
      switchMap((params) => {
        this.projectId = params.get('projectId');
        this.accountId = params.get('accountId');
        console.log('mekkok', this.accountId, this.projectId);

        return this.projectService.getMyProjects(this.accountId);
      }),
      switchMap((e) => of(e.find((p) => p.id == this.projectId)))
    );

    this.activeAccount$ = this.activatedRoute.paramMap.pipe(
      switchMap((params) => {
        this.projectId = params.get('projectId');
        this.accountId = params.get('accountId');

        return this.accountService.getMyAccounts$();
      }),
      switchMap((e) => of(e.find((p) => p.id == this.accountId)))
    );

    this.subscription.add(
      this.activeProject$.subscribe((project) => {
        this.experts = project?.experts || [];
        this.isOnboardingDone =
          project.onboardingGroups &&
          project.onboardingGroups.every(
            (e) => e.steps && e.steps.every((s) => s.done)
          );
      })
    );

    if (
      this.auth.hasAccess(ACCESS_TYPE.legal, this.accountId, this.projectId)
    ) {
      this.subscription.add(
        legalsService
          .getLegalsForProject$(this.projectId)
          .subscribe((legals) => {
            this.isLegalWarning = !!legals.find((legal) => legal.isWarning());
          })
      );
    }

    if (
      this.auth.hasAccess(ACCESS_TYPE.invoices, this.accountId, this.projectId)
    ) {
      this.subscription.add(
        invoicesService
          .getInvoicesForProject$(this.projectId)
          .subscribe((invoices) => {
            this.isUnpaidInvoice = !!invoices.find(
              (invoice) => invoice.status === INVOICE_STATUS.UNPAID
            );
          })
      );
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {}
}
