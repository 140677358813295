<div *ngIf="account; else loading">
  <div
    class="hero-image"
    [ngStyle]="{
      'background-image':
        'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(' +
        account.logo +
        ')'
    }"
  >
    <div class="hero-text">
      <h1>
        {{ account?.name }}
      </h1>
      <button
        mat-flat-button
        *ngIf="auth.isSuperAdmin"
        [routerLink]="['/account-add', accountId]"
      >
        <mat-icon>edit</mat-icon>
        Edit
      </button>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-md-4" *ngFor="let project of projects$ | async">
        <mat-card>
          <a
            [routerLink]="['/account', project.account, 'project', project.id]"
          >
            <mat-card-header>
              <mat-card-title>{{ project.name }}</mat-card-title>
              <!-- <mat-card-subtitle>subtitle</mat-card-subtitle> -->
            </mat-card-header>
            <img
              mat-card-image
              src="{{ project.logo }}"
              alt="{{ project.name }}"
            />
            <mat-card-content></mat-card-content>
          </a>
          <mat-card-actions *ngIf="auth.isSuperAdmin" class="text-center">
            <button
              mat-raised-button
              color="primary"
              [routerLink]="['project-add', project.id]"
            >
              <mat-icon>edit</mat-icon>
              Edit
            </button>
            <button mat-raised-button color="warn" (click)="remove(project)">
              <mat-icon>delete</mat-icon>Remove
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
    <br />
    <br />
    <br />
    <mat-card *ngIf="auth.isSuperAdmin">
      <mat-card-header>
        <mat-card-title>SuperAdmin Section</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <button
          mat-raised-button
          color="accent"
          [routerLink]="['./project-add']"
        >
          <mat-icon>add</mat-icon>Add project
        </button>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #loading>Loading account..</ng-template>
