import { Injectable } from '@angular/core';
import { Observable, of, combineLatest } from 'rxjs';
import { IProject } from '../models/project';
import { DEFAULT_ONBOARDING_GROUPS } from '../models/onboardingStep';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { map, switchMap, filter, tap, shareReplay } from 'rxjs/operators';
import { transformFirebase, getDocs } from './helpers';
import { USER_ROLES } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private _myProjects$: Observable<IProject[]> = null;
  private _projectsMap$: { [id: string]: Observable<IProject[]> } = {};
  constructor(private afs: AngularFirestore, private auth: AuthService) {
    // this._myProjects$ = this.getMyProjects();
  }

  createProject({
    id,
    account,
    name,
    users,
    invoices,
    legals,
    logo,
    experts,
    deliverables,
    dashboards,
  }: IProject) {
    this.afs
      .collection<IProject>(`projects`)
      .add({
        ...(id && { id: id }),
        ...(account && { account: account }),
        ...(name && { name: name }),
        ...(users && { users: users }),
        ...(invoices && { invoices: invoices }),
        ...(legals && { legals: legals }),
        ...(logo && { logo: logo }),
        ...(experts && { experts: experts }),
        ...(deliverables && { deliverables: deliverables }),
        ...(dashboards && { dashboards: dashboards }),
        onboardingGroups: DEFAULT_ONBOARDING_GROUPS,
      })
      .then((r) => {
        console.log('saved', r);
      })
      .catch(alert);
  }

  updateProject({
    id,
    account,
    name,
    users,
    invoices,
    legals,
    logo,
    experts,
    deliverables,
    dashboards,
    onboardingGroups,
  }: IProject) {
    this.afs
      .doc(`projects/${id}`)
      .set(
        {
          ...(id && { id: id }),
          ...(account && { account: account }),
          ...(name && { name: name }),
          ...(users && { users: users }),
          ...(invoices && { invoices: invoices }),
          ...(legals && { legals: legals }),
          ...(logo && { logo: logo }),
          ...(experts && { experts: experts }),
          ...(deliverables && { deliverables: deliverables }),
          ...(dashboards && { dashboards: dashboards }),
          ...(onboardingGroups && { onboardingGroups: onboardingGroups }),
        },
        {
          merge: true,
        }
      )
      .then(() => {
        console.log('yes', id);
      })
      .catch(alert);
  }

  getProject$(id: string): Observable<IProject> {
    return this.afs
      .doc<IProject>(`projects/${id}`)
      .valueChanges()
      .pipe(
        map((q) => transformFirebase<IProject>(q)),
        map((project) => {
          return { id: id, ...(project as IProject) };
        }),
        shareReplay(1)
      );
  }

  getMyProjects = (accountId = ''): Observable<IProject[]> => {
    // console.log(`getMyProjects${accountId}`);
    if (this.auth.isSuperAdmin) {
      return this.afs
        .collection<IProject>('projects', (ref) => {
          let query = ref.orderBy('name');
          if (accountId) {
            query = ref.where('account', '==', accountId);
          }
          return query;
        })
        .valueChanges({
          idField: 'id',
        })
        .pipe(shareReplay(1));
    }
    // console.log('NOOOOOOOOOOO super amdin');
    return this.auth.myAccessesMap$.pipe(
      switchMap((accesses) => {
        let ids = [].concat(Object.values(accesses));
        if (accountId) {
          ids = accesses[accountId];
        }
        return getDocs<IProject>(this.afs, 'projects', ids).pipe(
          // tap((e) => console.log('FUKEEEEEEEEEEEEER', e)),
          map((projects) =>
            projects.sort((p1, p2) => p1.name.localeCompare(p2.name))
          )
        );
      }),
    );
  };

  //   return this.auth.myAccesses$.pipe(
  //     switchMap((accessMap) => {
  //       if (!(accountId in accessMap)) {
  //         console.error('no access for requested account id', accountId);
  //         return of([]);
  //       }
  //       return combineLatest(
  //         getDocs<Project>(this.afs, 'projects', accessMap[accountId])
  //       ).pipe(
  //         switchMap((projects) =>
  //           of(projects.sort((p1, p2) => p1.name.localeCompare(p2.name)))
  //         )
  //       );
  //     })
  //   );
  // };

  // getAllProjects = (): Observable<Project[]> => {
  //   if (!this.auth.isSuperAdmin) {
  //     console.error('Get all projects can only superadmin, pls fix me');
  //     return of([]);
  //   }
  //   return this.afs
  //     .collection<Project>('projects', (ref) => {
  //       let query = ref.orderBy('name');
  //       // query = ref.where('account', '==', accountId);
  //       return query;
  //     })
  //     .valueChanges({
  //       idField: 'id',
  //     });
  // };

  // if (!(accountId in this._projectsMap$)) {
  //   this._projectsMap$[accountId] =
  //     switchMap(accessMap => {

  //     })
  //   )
  //   this._projectsMap$[accountId] = getDocs(afs, 'projects', projectIds);
  // }
  // return this._projectsMap$[accountId];

  //   this.afAuth.myAccesses$.pipe(
  //     switchMap(accessMap => {
  //       if (!user.roles || !user.roles.includes(USER_ROLES.SUPERADMIN)) {
  //         //           query = query.where('users', 'array-contains', this.afAuth.email);
  //         //         }
  //     })
  //   )
  // };
  // return this.afAuth.user$.pipe(
  //   // first()).toPromise()
  //   switchMap((user) => {
  //     // console.log(user.id);
  //     if (!user) return of([]);
  //     console.log(user);

  //     return this.afs
  //       .collection<Project>('projects', (ref) => {
  //         let query = ref.orderBy('name');
  //         if (accountId) {
  //           query = ref.where('account', '==', accountId);
  //         }
  //         if (!user.roles || !user.roles.includes(USER_ROLES.SUPERADMIN)) {
  //           query = query.where('users', 'array-contains', this.afAuth.email);
  //         }
  //         return query;
  //       })
  //       .valueChanges({
  //         idField: 'id',
  //       });
  //   }),
  //   tap(console.log)
  // );
  // };

  deleteProject(project: IProject) {
    this.afs
      .doc(`projects/${project.id}`)
      .delete()
      .then(() => {
        console.log('deleted', project.id);
      })
      .catch((err) => console.log('err', err));
  }
}
