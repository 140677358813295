export interface IAccess {
  // account-project-uid-type
  id?: string;

  account: string;
  project: string;
  user: string;
  legal: boolean;
  invoices: boolean;
  dashboards: boolean;
  delivery: boolean;
  admin: boolean;
  createdBy: string;
}

export enum ACCESS_TYPE {
  legal = 'legal',
  invoices = 'invoices',
  dashboards = 'dashboards',
  delivery = 'delivery',
  admin = 'admin',
  access = 'access',
}

export const getAccessId = (account: string, project: string, user: string) => {
  return `${account}-${project}-${user}`;
};
