import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";

import { AuthService } from "./auth.service";
import { Observable, of } from "rxjs";
import { tap, map, take, switchMap } from "rxjs/operators";
import { AppRoutingModule } from "../app-routing.module";
import { IUser } from '../models/user';

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    return this.auth.user$.pipe(
         take(1),
         map(user => !!user), // <-- map to boolean
         tap(loggedIn => {
           if (!loggedIn) {
             console.log('access denied')
             this.router.navigate(['/login']);
           }
       })
  )
}

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ): boolean {
  //   const loggedIn = !!this.auth.user;    
  //   if(!loggedIn) {
  //     this.router.navigate(['/login']);
  //   }
  //   return loggedIn;    
  // }
}


