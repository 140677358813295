import { Component, OnInit, OnDestroy } from '@angular/core';
import { IProject } from 'src/app/models/project';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ProjectService } from 'src/app/services/project.service';
import { Subscription, Observable } from 'rxjs';
import {
  DEFAULT_ONBOARDING_GROUPS,
  IOnboardingStep,
  OnboardingGroup,
} from 'src/app/models/onboardingStep';
import { IUser } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { UserSelectorComponent } from 'src/app/_components/user-selector/user-selector.component';
import { OnboardingComponent } from 'src/app/onboarding/onboarding.component';
import { FILE_TYPE } from 'src/app/services/files.service';

@Component({
  selector: 'app-project-add',
  templateUrl: './project-add.component.html',
  styleUrls: ['./project-add.component.scss'],
})
export class ProjectAddComponent implements OnInit, OnDestroy {
  fileType = FILE_TYPE.MEDIA;
  projectId = '';
  accountId = '';
  // users$: Observable<User[]>;

  project: IProject = {
    name: '',
    account: '',
    logo: '',
    dashboards: [],
    deliverables: [],
    experts: [],
    invoices: [],
    legals: [],
    onboardingGroups: DEFAULT_ONBOARDING_GROUPS,
    users: [],
  };

  subscribtion = new Subscription();
  constructor(
    public projectService: ProjectService,
    activatedRoute: ActivatedRoute,
    private usersService: UserService,

    public dialog: MatDialog
  ) {
    // this.users$ = usersService.users$;
    this.accountId = activatedRoute.snapshot.paramMap.get('accountId');
    this.project.account = this.accountId;
    this.projectId = activatedRoute.snapshot.paramMap.get('projectId');
    if (this.projectId) {
      projectService.getProject$(this.projectId).subscribe((project) => {
        this.project = project;
      });
    }
  }

  addUsers(): void {
    const dialogRef = this.dialog.open(UserSelectorComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.project.users = Array.from(
        new Set([...this.project.users, ...result])
      );
    });
  }

  addExperts(): void {
    const dialogRef = this.dialog.open(UserSelectorComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.project.experts = Array.from(
        new Set([...this.project.experts, ...result])
      );
    });
  }

  addOnboardingStep(group: OnboardingGroup, stepname: string): void {
    if (!stepname) return;
    // if (!this.project.onboardingGroups) this.project.onboardingGroups = [];
    group.steps.push({
      done: false,
      name: stepname,
    });
  }

  addOnboardingGroup(groupname: string): void {
    if (!groupname) return;
    if (!this.project.onboardingGroups) this.project.onboardingGroups = [];
    this.project.onboardingGroups.push({
      steps: [],
      name: groupname,
    });
  }

  removeStep(group: OnboardingGroup, step: IOnboardingStep): void {
    group.steps.splice(group.steps.indexOf(step), 1);
  }

  removeGroup(group: OnboardingGroup): void {
    this.project.onboardingGroups.splice(
      this.project.onboardingGroups.indexOf(group),
      1
    );
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscribtion.unsubscribe();
  }

  submit(): void {
    console.log(this.project)
    if (this.projectId) {      
      this.projectService.updateProject(this.project);
    } else {
      this.projectService.createProject(this.project);
    }
  }

  uploadedLogo(fileUrl: string) {
    this.project.logo = fileUrl;
  }
}
