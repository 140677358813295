<div>
  <mat-form-field appearance="outline" class="col-lg-4">
    <input
      matInput
      (keyup)="applyFilter($event.target.value)"
      placeholder="Search By Name"
    />
  </mat-form-field>

  <table
    mat-table
    class="full-width-table"
    [trackBy]="trackById"
    matSort
    aria-label="Files"
  >
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row">
        <a [href]="row.downloadURL">{{ row.name }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
      <td mat-cell *matCellDef="let row">{{ row.created | date }}</td>
    </ng-container>

    <ng-container matColumnDef="author">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Author</th>
      <td mat-cell *matCellDef="let row">{{ row.author }}</td>
    </ng-container>

    <ng-container matColumnDef="account">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Account</th>
      <td mat-cell *matCellDef="let row">{{ row.account }}</td>
    </ng-container>

    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Project</th>
      <td mat-cell *matCellDef="let row">{{ row.project }}</td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Media Type</th>
      <td mat-cell *matCellDef="let row">{{ row.type }}</td>
    </ng-container>

    <ng-container matColumnDef="size">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Size</th>
      <td mat-cell *matCellDef="let row">{{ row.size | filesize }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
      <td mat-cell *matCellDef="let row">
        <button mat-flat-button color="warn" (click)="delete(row)">
          <mat-icon>delete</mat-icon>Delete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    #paginator
    [length]="dataSource?.data.length"
    [pageIndex]="0"
    [pageSize]="25"
    [pageSizeOptions]="[25, 50, 100, 250]"
  >
  </mat-paginator>
</div>
