<div class="container">
  <div class="row">
    <div class="card">
      <div class="card-body">
        <h1 class="text-center">Add Invoice - {{ ownerName  | async }}</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <mat-card class="col-md-12">
      <!-- <mat-card-header>
        <mat-card-title>Add invoice </mat-card-title>
      </mat-card-header> -->
      <mat-card-content>
        <mat-form-field appearance="outline">
          <mat-label>name</mat-label>
          <input matInput [(ngModel)]="invoice.name" />
        </mat-form-field>
        <br />
        <div class="row">
          <mat-form-field class="col-sm-10" appearance="outline">
            <mat-label>price</mat-label>
            <input type="number" matInput [(ngModel)]="invoice.price" />
          </mat-form-field>
          <mat-form-field class="col-sm-2" appearance="outline">
            <mat-label>Currency</mat-label>
            <mat-select [(value)]="invoice.currency">
              <mat-option
                *ngFor="let currency of currency_types"
                [value]="currency"
                >{{ currency }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <br />
        <mat-form-field appearance="outline">
          <mat-label>Due Date</mat-label>
          <input
            matInput
            [matDatepicker]="duedate"
            [(ngModel)]="invoice.dueDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="duedate"
          ></mat-datepicker-toggle>
          <mat-datepicker #duedate></mat-datepicker>
        </mat-form-field>
        <br />
        <mat-label>Invoice Document</mat-label><br />
        <a
          mat-raised-button
          color="accent"
          *ngIf="invoice.url"
          [href]="invoice.url"
        >
          <mat-icon>cloud_download</mat-icon>
          Download Current Document
        </a>
        <app-uploader
          [fileType]="fileType"
          title="Upload New Document"
          (fileDownloadURL)="docUploaded($event)"
        ></app-uploader>

        <br />
        <!-- <mat-form-field appearance="outline">
          <mat-label>url</mat-label>
          <input matInput [(ngModel)]="invoice.url" />
        </mat-form-field>
        <br /> -->
        <mat-form-field appearance="outline">
          <mat-label>Invoice status</mat-label>
          <mat-select [(value)]="invoice.status">
            <mat-option *ngFor="let status of status_types" [value]="status">{{
              status
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <br />
      </mat-card-content>
      <mat-card-actions>
        <button
          mat-raised-button
          color="accent"
          [routerLink]="invoicesRoute"
          (click)="submit()"
        >
          <mat-icon>save</mat-icon>
          Save Invoice
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
<!-- <pre>
  {{ invoice | json }}
</pre> -->
