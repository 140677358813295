import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { ProjectOverviewComponent } from './project-overview/project-overview.component';
import { AuthGuard } from './services/auth.guard';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProductsComponent } from './products/products.component';
import { StatusComponent } from './status/status.component';
import { DeliverablesComponent } from './deliverables/deliverables.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { LegalComponent } from './legal/legal.component';
import { DashboardViewsComponent } from './dashboard-views/dashboard-views.component';
import { RoleGuard } from './services/role.guard';
import { USER_ROLES } from './models/user';
import { SuperAdminGuard } from './services/superadmin.guard';
import { InvoicesAddComponent } from './invoices-add/invoices-add.component';
import { InfoComponent } from './info/info.component';
import { AccountListComponent } from './account-list/account-list.component';
import { AccountOverviewComponent } from './account-overview/account-overview.component';
import { DashboardsAddComponent } from './dashboard-views-add/dashboard-views-add.component';
import { AccountsAddComponent } from './account-add/account-add.component';
import { ProjectAddComponent } from './admin/project-add/project-add.component';
import { LegalAddComponent } from './admin/legal-add/legal-add.component';
import { DeliverableAddComponent } from './admin/deliverable-add/deliverable-add.component';
import { AccessAddComponent } from './access-add/access-add.component';
import { AdminComponent } from './admin/admin.component';

// #0097a7
const routes: Routes = [
  {
    path: '',
    redirectTo: 'account',
    pathMatch: 'full',
  },
  { path: 'account-add', component: AccountsAddComponent },
  { path: 'account-add/:accountId', component: AccountsAddComponent },
  { path: 'account/:accountId/project-add', component: ProjectAddComponent },
  {
    path: 'account/:accountId/project-add/:projectId',
    component: ProjectAddComponent,
  },

  {
    path: 'account/:accountId/access-add',
    component: AccessAddComponent,
  },
  {
    path: 'account/:accountId/project/:projectId/access-add',
    component: AccessAddComponent,
  },

  { path: 'login', component: LoginComponent },

  {    
    path: 'profile',
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  {    
    path: 'profile/:accountId',
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  {    
    path: 'profile/:accountId/:projectId',
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  // { path: 'project/:id', component: DashboardComponent },
  {
    path: 'account',
    component: AccountListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/:accountId',
    component: AccountOverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/:accountId/info',
    component: InfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'status',
    component: StatusComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/:accountId/products',
    component: ProductsComponent,
    canActivate: [AuthGuard],
    // data: {
    //   role: USER_ROLES.ADMIN
    // }
  },
  {
    path: 'account/:accountId/project/:projectId',
    redirectTo: '/account/:accountId/project/:projectId/overview',
    pathMatch: 'full',
  },
  {
    path: 'account/:accountId/project/:projectId/overview',
    component: ProjectOverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/:accountId/project/:projectId/deliverables',
    component: DeliverablesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/:accountId/project/:projectId/deliverables-add',
    component: DeliverableAddComponent,
    canActivate: [AuthGuard],
  },
  {
    path:
      'account/:accountId/project/:projectId/deliverables-add/:deliverableId',
    component: DeliverableAddComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/:accountId/project/:projectId/dashboards',
    component: DashboardViewsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/:accountId/project/:projectId/dashboards-add',
    component: DashboardsAddComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/:accountId/project/:projectId/dashboards-add/:dashboardId',
    component: DashboardsAddComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'account/:accountId/legals',
    component: LegalComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/:accountId/project/:projectId/legals',
    component: LegalComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/:accountId/legals-add',
    component: LegalAddComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/:accountId/legals-add/:legalId',
    component: LegalAddComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/:accountId/project/:projectId/legals-add',
    component: LegalAddComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/:accountId/project/:projectId/legals-add/:legalId',
    component: LegalAddComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'account/:accountId/invoices',
    component: InvoicesComponent,
    canActivate: [AuthGuard],
    // data: {
    //   role: [USER_ROLES.ADMIN]
    // }
  },
  {
    path: 'account/:accountId/project/:projectId/invoices',
    component: InvoicesComponent,
    canActivate: [AuthGuard],
    // data: {
    //   role: [USER_ROLES.ADMIN]
    // }
  },
  {
    path: 'account/:accountId/invoices-add',
    component: InvoicesAddComponent,
    canActivate: [AuthGuard],
    // data: {
    //   role: [USER_ROLES.ADMIN]
    // }
  },
  {
    path: 'account/:accountId/invoices-add/:invoiceId',
    component: InvoicesAddComponent,
    canActivate: [AuthGuard],
    // data: {
    //   role: [USER_ROLES.ADMIN]
    // }
  },
  {
    path: 'account/:accountId/project/:projectId/invoices-add',
    component: InvoicesAddComponent,
    canActivate: [AuthGuard],
    // data: {
    //   role: [USER_ROLES.ADMIN]
    // }
  },
  {
    path: 'account/:accountId/project/:projectId/invoices-add/:invoiceId',
    component: InvoicesAddComponent,
    canActivate: [AuthGuard],
    // data: {
    //   role: [USER_ROLES.ADMIN]
    // }
  },

  {
    path: 'account/:accountId/project/:projectId/onboarding',
    component: OnboardingComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'admin',
  //   // component: AdminModule,
  //   loadChildren: () =>
  //     import('./admin/admin.module').then((m) => m.AdminModule),
  //   canActivate: [AuthGuard],
  // },

  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/:accountId',
    component: AdminComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/:accountId/:projectId',
    component: AdminComponent,
    canActivate: [AuthGuard],
  },

  { path: '**', component: PageNotFoundComponent },

  // {
  //   path: '',
  //   component: AdminLayoutComponent,
  //   children: [
  //     {
  //       path: '',
  //       loadChildren:
  //         './layouts/admin-layout/admin-layout.module#AdminLayoutModule',
  //     },
  //   ],
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
