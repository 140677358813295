<div class="main-content">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-success">
            <h4 class="card-title">Billing Information</h4>
            <!-- <p class="card-category">Complete your profile</p> -->
          </div>
          <div
            class="card-body"
            [innerHTML]="account?.billingInfo | safehtml"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
