import { Component, OnInit } from '@angular/core';
import { LegalComponent } from 'src/app/legal/legal.component';
import { LegalDocument, LEGAL_TYPE, LEGAL_STATUS } from 'src/app/models/legal';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { LegalsService } from 'src/app/services/legal.service';
import * as dayjs from 'dayjs';
import { FILE_TYPE } from 'src/app/services/files.service';
import { UserStateService } from 'src/app/services/user-state.service';
import { ProjectService } from 'src/app/services/project.service';
import { AccountService } from 'src/app/services/account.service';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-legal-add',
  templateUrl: './legal-add.component.html',
  styleUrls: ['./legal-add.component.scss'],
})
export class LegalAddComponent implements OnInit {
  fileType = FILE_TYPE.LEGAL;
  id = '';
  projectId = '';
  accountId = '';
  legalsRoute = [];

  ownerName = of('');

  legalTypes = Object.values(LEGAL_TYPE);
  legalStatuses = Object.values(LEGAL_STATUS);

  legalDocument: LegalDocument = new LegalDocument({
    name: '',
    author: '',
    authorName: '',
    created: new Date(),
    description: '',
    type: LEGAL_TYPE.PROPOSALS,
    project: '',
    account: '',
    url: '',
    endDate: dayjs().add(1, 'month').toDate(),
    hasTerminationDate: true,
    startDate: new Date(),
    legalStatus: LEGAL_STATUS.PREPARATION,
  });
  constructor(
    public auth: AuthService,
    public legalsService: LegalsService,
    public userStateService: UserStateService,
    private projectService: ProjectService,
    private accountService: AccountService,
    activatedRoute: ActivatedRoute
  ) {
    this.legalDocument.author = auth.email;
    this.legalDocument.authorName = auth.user.displayName || auth.user.email;

    this.id = activatedRoute.snapshot.paramMap.get('legalId') || '';
    this.projectId = activatedRoute.snapshot.paramMap.get('projectId') || '';
    this.accountId = activatedRoute.snapshot.paramMap.get('accountId') || '';
    this.legalsRoute = this.projectId
      ? ['/account', this.accountId, 'project', this.projectId, 'legals']
      : ['/account', this.accountId, 'legals'];
    
    this.ownerName = this.projectId
      ? projectService
          .getProject$(this.projectId)
          .pipe(map((proj) => proj.name))
      : this.accountId
      ? accountService
          .getAccount$(this.accountId)
          .pipe(map((proj) => proj.name))
      : of('');

    this.legalDocument.project = this.projectId;
    this.legalDocument.account = this.accountId;
    if (this.id) {
      this.legalsService.getLegal$(this.id).subscribe((legal) => {
        this.legalDocument = legal;
      });
    }
  }

  ngOnInit(): void {}

  submit(): void {
    if (this.id) {
      this.legalsService.updateLegal(this.legalDocument);
    } else {
      this.legalsService.createLegal(this.legalDocument);
    }
  }

  uploaded(url: string) {
    this.legalDocument.url = url;
  }
}
