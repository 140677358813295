export interface IOnboardingStep {
  name: string;
  done: boolean;
}

export interface OnboardingGroup {
  name: string;
  steps: IOnboardingStep[];
}

export let DEFAULT_ONBOARDING_GROUPS: OnboardingGroup[] = [
  {
    name: 'Data Health',
    steps: [
      {
        done: true,
        name: 'App Stores (with revenue views)',
      },
      {
        done: false,
        name: 'Platforms, where previous UA was running',
      },
      {
        done: false,
        name: '(Facebook, Google UAC, …)',
      },
      {
        done: false,
        name: 'Attribution platform (Kochava, AppsFlyer, ...)',
      },
    ],
  },
  {
    name: 'Data Infrastructure',
    steps: [
      {
        done: true,
        name:
          'Filled SuperScale data infrastructure questionnaire (link below)',
      },
    ],
  },
  {
    name: 'UA',
    steps: [
      {
        done: true,
        name: 'Access to your Ad account on FB ',
      },
      {
        done: true,
        name: 'Access to your App and Page on FB ',
      },
      {
        done: true,
        name: 'Access to the Google play store and iTunes connect ',
      },
      {
        done: true,
        name: 'Access to the attribution partner (Appsflyer, Kochava etc.)',
      },
    ],
  },
  {
    name: 'Creatives',
    steps: [
      {
        done: true,
        name:
          'Access to your creative folder including all your assets (all in game assets including UI elements, logos, fonts, animations, 3D models & renders, sound files, all previous creatives used in UA preferably in open data formats)',
      },
      {
        done: true,
        name: 'Debug Mode, (or in-game currency & other way to hide UI)',
      },
      {
        done: true,
        name: 'Historical data on how specific creatives performed',
      },
      {
        done: true,
        name: 'Top performing ads',
      },
      {
        done: true,
        name:
          'Brand guidelines & manual (if non existant, DOs and DON’Ts will suffice)',
      },
    ],
  },
  {
    name: 'ASO',
    steps: [
      {
        done: true,
        name: 'Google Play Console Access  ',
      },
      {
        done: true,
        name: 'Access to Store Listing Experiments in GP Console',
      },
    ],
  },
  {
    name: 'Game Design',
    steps: [
      {
        done: true,
        name:
          'Game Design Document and other design related documents (excel sheets, wireframes, etc.)',
      },
      {
        done: true,
        name: 'Game Roadmap',
      },
      {
        done: true,
        name: 'Team Composition and Experience',
      },
      {
        done: true,
        name: 'Basic KPIs, access to internal dashboards',
      },
      {
        done: true,
        name: 'Target KPIs',
      },
    ],
  },
  {
    name: 'Ad monetization',
    steps: [
      {
        done: true,
        name:
          'Access to Ad monetization platform (including revenue and eCPM insights)',
      },
      {
        done: true,
        name: 'Insights regarding the Ad Networks relationships',
      },
    ],
  },
  {
    name: 'IAP monetization',
    steps: [
      {
        done: true,
        name: 'Current offer system accesses & design documentations',
      },
      {
        done: true,
        name: 'Future plans regarding monetization & offer system',
      },
      {
        done: true,
        name: 'Team’s previous experiences with IAP personalization',
      },
      {
        done: true,
        name: 'IAP LTV improvements goals',
      },
    ],
  },
];
