<h3>Add Team member</h3>
<p>
  <mat-form-field appearance="outline">
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="expert.name" placeholder="Name" />
    <mat-icon matSuffix>account_circle</mat-icon>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Specialization</mat-label>
    <input matInput [(ngModel)]="expert.title" placeholder="Specialization" />
    <mat-icon matSuffix>assignment_ind</mat-icon>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Contact</mat-label>
    <input matInput [(ngModel)]="expert.email" placeholder="Contact" />
    <mat-icon matSuffix>email</mat-icon>
  </mat-form-field>
  <button mat-raised-button color="accent" (click)="saveExpert()">
    <mat-icon>add</mat-icon>
    Add expert
  </button>
</p>
