import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'; // import plugin
dayjs.extend(relativeTime); // use plugin

export interface ILegalDocument {
  id?: string;
  name: string;
  author: string;
  authorName: string;
  created: Date;
  description: string;
  type: LEGAL_TYPE;
  project: string;
  account: string;
  url: string;
  startDate: Date;
  endDate: Date;
  hasTerminationDate: boolean;
  legalStatus: LEGAL_STATUS;
}

export class LegalDocument implements ILegalDocument {
  public id?: string;
  public name: string;
  public author: string;
  public authorName: string;
  public created: Date;
  public description: string;
  public type: LEGAL_TYPE;
  public project: string;
  public account: string;
  public url: string;
  public startDate: Date;
  public endDate: Date;
  public hasTerminationDate: boolean;
  public legalStatus: LEGAL_STATUS;

  constructor(data?: Partial<LegalDocument>) {
    Object.assign(this, data);
  }

  public isActive(): boolean {
    return (
      this.legalStatus === LEGAL_STATUS.SIGNED &&
      (this.hasTerminationDate ? new Date() < this.endDate : true)
    );
  }

  public remainingActiveDays(): string {
    return dayjs(this.endDate).fromNow();
  }

  public isWarning(): boolean {
    return (
      this.legalStatus === LEGAL_STATUS.SIGNED &&
      this.hasTerminationDate &&
      dayjs(this.endDate).diff(dayjs(), 'day') < 14
    );
  }
}

export enum LEGAL_TYPE {
  MNDA = 'mNDA',
  PROPOSALS = 'proposals',
  AGREEMENTS = 'agreements',
  DPA = 'DPA',
  MSA = 'MSA',
  WO = 'WO',
}

export enum LEGAL_STATUS {
  PREPARATION = 'in legal preparation',
  REVIEW = 'in your legal review',
  COUNTERSIGN = 'to countersign',
  SIGNED = 'signed',
  NONE = '',
}
