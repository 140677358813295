import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Expert } from '../models/expert';
import { MatDialog } from '@angular/material/dialog';
import { ExpertAddComponent } from '../expert-add/expert-add.component';

@Component({
  selector: 'app-expert-list',
  templateUrl: './expert-list.component.html',
  styleUrls: ['./expert-list.component.scss'],
})
export class ExpertListComponent implements OnInit {
  @Input() edit = false;
  @Input() experts: Expert[] = [];
  @Output() expertsChanged = new EventEmitter<Expert[]>();

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  addExpert() {
    this.dialog
      .open(ExpertAddComponent)
      .afterClosed()
      .subscribe((editedExpert) => {
        console.log(editedExpert, 'kokookkokokoooo');
        this.experts.push(editedExpert);
        this.expertsChanged.emit([...this.experts]);
      });
  }

  editExpert(expert: Expert) {
    this.dialog
      .open(ExpertAddComponent, {
        data: expert,
      })
      .afterClosed()
      .subscribe((editedExpert) => {
        console.log(editedExpert);
        this.expertsChanged.emit([...this.experts]);
      });
  }

  removeExpert(expert: Expert) {
    this.experts.splice(this.experts.indexOf(expert), 1);
    this.expertsChanged.emit(this.experts);
  }
}
