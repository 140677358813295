import { Injectable } from '@angular/core';
import { IUser } from '../models/user';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { firestore } from 'firebase';
import { transformFirebase } from './helpers';
import { IDeliverable } from '../models/deliverable';

@Injectable({
  providedIn: 'root',
})
export class DeliverableService {
  private COLLECTION = 'deliverables';

  public deliverablesMapAccounts: {
    [accountId: string]: Observable<IDeliverable[]>;
  } = {};
  public deliverablesMapProjects: {
    [projectId: string]: Observable<IDeliverable[]>;
  } = {};

  constructor(private afs: AngularFirestore) {}

  getDeliverable(id: string): Observable<IDeliverable> {
    return this.afs
      .doc<IDeliverable>(`deliverables/${id}`)
      .valueChanges()
      .pipe(
        map((q) => transformFirebase<IDeliverable>(q)),
        map((deliverable) => {
          return { id: id, ...deliverable };
        })
      );
  }

  getDeliverablesForProject(projectId: string): Observable<IDeliverable[]> {
    if (!(projectId in this.deliverablesMapProjects)) {
      this.deliverablesMapProjects[projectId] = this.afs
        .collection<IDeliverable>('deliverables', (ref) =>
          ref.where('project', '==', projectId).orderBy('created', 'desc')
        )
        .valueChanges({ idField: 'id' })
        .pipe(map((q) => q.map((item) => transformFirebase<any>(item))));
    }
    return this.deliverablesMapProjects[projectId];
  }

  getDeliverablesForAccount(accountId: string): Observable<IDeliverable[]> {
    if (!(accountId in this.deliverablesMapAccounts)) {
      this.deliverablesMapAccounts[accountId] = this.afs
        .collection<IDeliverable>('deliverables', (ref) =>
          ref.where('account', '==', accountId).orderBy('created', 'desc')
        )
        .valueChanges({ idField: 'id' })
        .pipe(map((q) => q.map((item) => transformFirebase<any>(item))));
    }
    return this.deliverablesMapAccounts[accountId];
  }

  createDeliverable({
    id,
    name,
    url,
    author,
    authorName,
    created,
    type,
    description,
    project,
    account,
  }: IDeliverable) {
    this.afs
      .collection<IDeliverable>(`deliverables`)
      .add({
        ...(id && { id: id }),
        ...(name && { name: name }),
        ...(url && { url: url }),
        ...(author && { author: author }),
        ...(authorName && { authorName: authorName }),
        ...(created && { created: created }),
        ...(type && { type: type }),
        ...(description && { description: description }),
        ...(project && { project: project }),
        ...(account && { account: account }),
      })
      .then(() => {
        console.log('pls');
      })
      .catch(alert);
  }

  updateDeliverable({
    id,
    name,
    url,
    author,
    authorName,
    created,
    type,
    description,
    project,
    account,
  }: IDeliverable) {
    console.log(id, name, author, authorName, created, description, url);
    this.afs
      .doc(`deliverables/${id}`)
      .set(
        {
          ...(id && { id: id }),
          ...(name && { name: name }),
          ...(url && { url: url }),
          ...(author && { author: author }),
          ...(authorName && { authorName: authorName }),
          ...(created && { created: created }),
          ...(type && { type: type }),
          ...(description && { description: description }),
          ...(project && { project: project }),
          ...(account && { account: account }),
        },
        {
          merge: true,
        }
      )
      .then(() => {
        console.log('yes', id, status);
      })
      .catch(alert);
  }

  deleteDeliverable(deliverable: IDeliverable) {
    this.afs.doc(`${this.COLLECTION}/${deliverable.id}`).delete();
  }
}
