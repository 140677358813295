<div class="container">
  <div class="row">
    <div class="card">
      <div class="card-body">
        <h1 class="text-center">SuperAdmin Section</h1>
        <p>
          Here you can check all the uploaded files on server, later we will add
          here some more Portal system wide options.
        </p>
      </div>
    </div>
  </div>

  <mat-card>
    <mat-card-header>
      <mat-card-title
        ><mat-icon inline>folder</mat-icon> Files Management</mat-card-title
      >
    </mat-card-header>
    <mat-card-content>
      <app-files-list-table></app-files-list-table>
    </mat-card-content>
    <!-- <mat-card-actions>
    <button mat-button>Ok</button>
  </mat-card-actions> -->
  </mat-card>
</div>
