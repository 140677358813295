import {
  Component,
  OnInit,
  OnDestroy,
  forwardRef,
  Inject,
} from '@angular/core';
import { IUser } from 'src/app/models/user';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { Subscription, Observable } from 'rxjs';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSelectorComponent),
      multi: true,
    },
  ],
})
export class UserSelectorComponent implements ControlValueAccessor {
  private _value: string[] = [];
  users$: Observable<IUser[]>;
  // selectedUsers: string[] = [];

  onChange = (_) => {};
  onTouched = () => {};

  constructor(
    activatedRoute: ActivatedRoute,
    userService: UserService,
    public dialogRef: MatDialogRef<UserSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public selectableUsers$: Observable<IUser[]>
  ) {
    if (selectableUsers$) {
      this.users$ = selectableUsers$;
    }
    this.users$ = userService.users$;
  }

  public get selectedUsersIds(): string[] {
    return this._value;
  }
  public set selectedUsersIds(v: string[]) {
    if (v !== this._value) {
      console.log('changed', v);
      this._value = v;
      this.onChange(v);
    }
  }

  writeValue(value: any): void {
    console.log(value);
    this.selectedUsersIds = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  close() {
    this.dialogRef.close(this.selectedUsersIds);
  }
}
