import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime'; // import plugin
// import "dayjs/locale/sk"; // import locale

dayjs.extend(relativeTime); // use plugin
// dayjs.locale("sk"); // use locale

@Pipe({
  name: 'relativeTime',
})
export class RelativeTimePipe implements PipeTransform {
  transform(value: Date, ...args: unknown[]): string {
    return dayjs(value).fromNow();
  }
}
