import { Injectable } from '@angular/core';
import { IUser } from '../models/user';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';

import { transformFirebase } from './helpers';
import { AuthService } from './auth.service';
import { IDashboard } from '../models/dashboard';

@Injectable({
  providedIn: 'root',
})
export class DashboardsService {
  public dashboardsMap: { [projectId: string]: Observable<IDashboard[]> } = {};
  constructor(private afs: AngularFirestore, private afAuth: AuthService) {}

  createDashboard({
    name,
    url,
    author,
    height,
    project,
    account,
    description,
  }: IDashboard) {
    this.afs
      .collection<IDashboard>(`dashboards`)
      .add({
        ...(name && { name: name }),
        ...(url && { url: url }),
        ...(author && { author: author }),
        ...(height && { height: height }),
        ...(project && { project: project }),
        ...(account && { account: account }),
        ...(description && { description: description }),
        created: new Date(),
      })
      .then((e) => console.log(e.id))
      .catch(alert);
  }

  updateDashboard({
    id,
    name,
    url,
    author,
    height,
    project,
    account,
    description,
  }: IDashboard) {
    if (!id) throw Error('No id specified for dashboard to update');
    this.afs
      .doc(`dashboards/${id}`)
      .set(
        {
          ...(name && { name: name }),
          ...(url && { url: url }),
          ...(author && { author: author }),
          ...(height && { height: height }),
          ...(project && { project: project }),
          ...(account && { account: account }),
          ...(description && { description: description }),
        },
        {
          merge: true,
        }
      )
      .then(() => {
        console.log('yes', id, status);
      })
      .catch(alert);
  }

  getDashboard(id: string): Observable<IDashboard> {
    return this.afs
      .doc<IDashboard>(`dashboards/${id}`)
      .valueChanges()
      .pipe(
        map((q) => transformFirebase<IDashboard>(q)),
        map((dashboard) => {
          return { id: id, ...dashboard };
        })
      );
  }

  getDashboardsForProject(projectId: string): Observable<IDashboard[]> {
    if (!(projectId in this.dashboardsMap)) {
      this.dashboardsMap[projectId] = this.afs
        .collection<IDashboard>('dashboards', (ref) =>
          ref.where('project', '==', projectId).orderBy('name')
        )
        .valueChanges({ idField: 'id' })
        .pipe(
          map((q) => q.map((item) => transformFirebase<any>(item))),
          tap(console.log)
        );
    }
    return this.dashboardsMap[projectId];
  }

  deleteDashboard(dashboard: IDashboard) {
    this.afs
      .doc(`dashboards/${dashboard.id}`)
      .delete()
      .then(() => {
        console.log('yess', dashboard.id);
      })
      .catch((err) => console.log('err', err));
  }
}
