<div class="container">
  <div class="row">
    <div class="card">
      <div class="card-body">
        <h1 class="text-center">Add Legal - {{ ownerName | async }}</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <mat-card class="col-md-12">
      <!-- <mat-card-header>
        <mat-card-title>Add Legal Document {{ id }}</mat-card-title>
      </mat-card-header> -->
      <mat-card-content>
        <form>
          <mat-form-field appearance="outline">
            <mat-label>name </mat-label>
            <input
              matInput
              placeholder="name"
              name="name"
              [(ngModel)]="legalDocument.name"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Legal Type</mat-label>
            <mat-select [(value)]="legalDocument.type">
              <mat-option
                *ngFor="let legalType of legalTypes"
                [value]="legalType"
                >{{ legalType }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <br />
          <mat-form-field appearance="outline">
            <mat-label>Start Date</mat-label>
            <input
              matInput
              [matDatepicker]="startdate"
              [(ngModel)]="legalDocument.startDate"
              [ngModelOptions]="{ standalone: true }"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="startdate"
            ></mat-datepicker-toggle>
            <mat-datepicker #startdate></mat-datepicker>
          </mat-form-field>
          <br />
          <mat-slide-toggle
            color="accent"
            [(ngModel)]="legalDocument.hasTerminationDate"
            [ngModelOptions]="{ standalone: true }"
          >
            Has Termination Date
          </mat-slide-toggle>
          <br />
          <mat-form-field
            appearance="outline"
            *ngIf="legalDocument.hasTerminationDate"
          >
            <mat-label>Termination Date</mat-label>
            <input
              matInput
              [matDatepicker]="enddate"
              [(ngModel)]="legalDocument.endDate"
              [ngModelOptions]="{ standalone: true }"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="enddate"
            ></mat-datepicker-toggle>
            <mat-datepicker #enddate></mat-datepicker>
          </mat-form-field>
          <br />
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select [(value)]="legalDocument.legalStatus">
              <mat-option
                *ngFor="let status of legalStatuses"
                [value]="status"
                >{{ status }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <br />
          <br />
          <mat-label>Description</mat-label>
          <editor
            [init]="{
              base_url: '/tinymce',
              suffix: '.min',
              height: 200,
              menubar: true,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help'
            }"
            [(ngModel)]="legalDocument.description"
            [ngModelOptions]="{ standalone: true }"
          ></editor>
          <br />

          <a
            *ngIf="legalDocument.url"
            mat-raised-button
            [href]="legalDocument.url"
            color="accent"
          >
            <mat-icon>cloud_download</mat-icon>
            Current Document Link
          </a>
          <app-uploader
            [fileType]="fileType"
            title="Upload Document"
            (fileDownloadURL)="uploaded($event)"
          ></app-uploader>
        </form>
      </mat-card-content>
      <mat-card-actions>
        <button
          mat-raised-button
          color="accent"
          [routerLink]="legalsRoute"
          (click)="submit()"
        >
          <mat-icon>save</mat-icon>
          Save
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<!-- <mat-card class="col-md-6">
            <mat-card-header>
              <mat-card-title>Proj info</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <pre>
            {{ legalDocument | json }}
          </pre
              >
            </mat-card-content>
          </mat-card> -->
