<editor
  #editor
  [init]="{
    base_url: '/tinymce',
    suffix: '.min',
    height: 500,
    menubar: true,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount imagetools'
    ],
    toolbar:
      'undo redo | formatselect | bold italic backcolor | \
alignleft aligncenter alignright alignjustify | \
bullist numlist outdent indent | removeformat | help'
  }"
  [ngModel]="text"
  (ngModelChange)="modelChanged($event)"
></editor>
<app-uploader
  [fileType]="fileType"
  title="Media Uploader"
  (fileDownloadURL)="mediaUploaded($event)"
  [limit]="10"
  showDelete="true"
></app-uploader>
