import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FilesService } from '../services/files.service';
import { IIFile } from '../models/file';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-files-list-table',
  templateUrl: './files-list-table.component.html',
  styleUrls: ['./files-list-table.component.scss'],
})
export class FilesListTableComponent
  implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<IIFile>;
  dataSource: MatTableDataSource<IIFile>;

  subscriptions = new Subscription();
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'name',
    'project',
    'account',
    'type',
    'size',
    'author',
    'created',
    'actions',
  ];
  constructor(private filesService: FilesService) {}

  ngOnInit() {}

  trackById(index, item: IIFile) {
    return item.id;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    this.subscriptions.add(
      this.filesService.listAllFiles$().subscribe((data) => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.table.dataSource = this.dataSource;
      })
    );
  }

  ngOnDestroy(): void {
    console.log('DESTROYING ME :(');
    this.subscriptions.unsubscribe();
  }

  delete(file: IIFile) {
    if (
      confirm(
        'Are you sure you want to delete this file it may affect, clients documents!'
      )
    ) {
      this.filesService.deleteFile(file);
    }
  }
}
