import { User } from 'firebase';

export interface IDeliverable {
  id?: string;
  name: string;
  url: string;
  author: User | string;
  authorName: string;
  created: Date;
  type: DELIVERABLE_TYPE;
  description: string;
  project: string;
  account: string;
}

export enum DELIVERABLE_TYPE {
  PRESENTATION = 'presentation',
  WEEKLY_UPDATE = 'weekly_update',
}
