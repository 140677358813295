<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
  <p>{{ message }}</p>
</div>
<div mat-dialog-actions>
  <div class="mx-auto">
    <button mat-raised-button color="accent" (click)="onClose(true)">
      {{ ok }}
    </button>
    <button mat-button color="warn" (click)="onClose(false)">
      {{ cancel }}
    </button>
  </div>
</div>
