import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { Observable } from 'rxjs';
import { IAccount } from '../models/account';
import { AuthService } from '../services/auth.service';

@Component({
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss'],
})
export class AccountListComponent implements OnInit {
  accounts$: Observable<IAccount[]>;

  constructor(public accountService: AccountService, public auth: AuthService) {
    this.accounts$ = this.accountService.getMyAccounts$();
  }

  ngOnInit(): void {}

  remove(account: IAccount) {
    if (confirm(`Are you sure you want to DELETE ${account.name}?`)) {
      if (
        confirm(
          `Are you really really sure you want to DELETE ${account.name}?`
        )
      ) {
        this.accountService.deleteAccount(account);
      }
    }
  }
}
