<div class="container">
  <h1>Your Accounts</h1>
  <div class="row" *ngIf="accounts$ | async as accounts; else loading">
    <div *ngFor="let account of accounts" class="col-md-4">
      <mat-card [routerLink]="['/account', account.id]">
        <mat-card-header>
          <mat-card-title>{{ account.name }}</mat-card-title>
          <!-- <mat-card-subtitle>subtitle</mat-card-subtitle> -->
        </mat-card-header>
        <img mat-card-image src="{{ account.logo }}" alt="{{ account.name }}" />
        <mat-card-content> </mat-card-content>
        <mat-card-actions *ngIf="auth.isSuperAdmin">
          <button
            mat-raised-button
            color="primary"
            [routerLink]="['/account-add', account.id]"
          >
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          <button mat-raised-button color="warn" (click)="remove(account)">
            <mat-icon>delete</mat-icon>
            Remove
          </button>
        </mat-card-actions>
      </mat-card>
      <br />
    </div>

    <mat-card class="col-md-12" *ngIf="!accounts || accounts.length === 0">
      You aren't currently in any account. Please contact us on our Slack to give you access.
    </mat-card>
  </div>

  <ng-template #loading> Loading... </ng-template>
</div>
<br />
<br />
<br />
<div class="container">
  <mat-card *ngIf="auth.isSuperAdmin">
    <mat-card-header>
      <mat-card-title>SuperAdmin Section</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <button mat-raised-button color="accent" [routerLink]="['/account-add']">
        <mat-icon>add</mat-icon>Add account
      </button>
    </mat-card-content>
  </mat-card>
</div>
