import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IAccount } from '../models/account';
import { DEFAULT_ONBOARDING_GROUPS } from '../models/onboardingStep';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { map, switchMap } from 'rxjs/operators';
import { transformFirebase, getDocs } from './helpers';
import { USER_ROLES } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private _myAccounts$: Observable<IAccount[]> = null;
  private accounts$: Observable<IAccount[]>;
  // private accounts$: Observable<Account[]>;
  public accountsMap: { [id: string]: IAccount } = {};
  constructor(private afs: AngularFirestore, private auth: AuthService) {
    this.accounts$ = this.afs
      .collection<any>('accounts')
      .valueChanges({
        idField: 'id',
      })
      .pipe(
        map((accounts) => accounts.map((q) => transformFirebase<IAccount>(q)))
      );
    this.accounts$.subscribe((accounts) => {
      // this.accounts = accounts;
      accounts.forEach((account) => (this.accountsMap[account.id] = account));
    });
  }

  createAccount({ id, name, users, billingInfo, logo }: IAccount) {
    console.log(id, name, users, billingInfo, logo);
    this.afs
      .collection<IAccount>(`accounts`)
      .add({
        ...(id && { id: id }),
        ...(name && { name: name }),
        ...(users && { users: users }),
        ...(billingInfo && { billingInfo: billingInfo }),
        ...(logo && { logo: logo }),
        projects: [],
      })
      .then((r) => {
        console.log('saved', r);
      })
      .catch(alert);
  }

  updateAccount({ id, name, users, projects, billingInfo, logo }: IAccount) {
    this.afs
      .doc(`accounts/${id}`)
      .set(
        {
          ...(id && { id: id }),
          ...(name && { name: name }),
          ...(users && { users: users }),
          ...(billingInfo && { billingInfo: billingInfo }),
          ...(projects && { projects: projects }),
          ...(logo && { logo: logo }),
        },
        {
          merge: true,
        }
      )
      .then(() => {
        console.log('yes', id);
      })
      .catch(alert);
  }

  getAccount$(id: string): Observable<IAccount> {
    return this.afs
      .doc<IAccount>(`accounts/${id}`)
      .valueChanges()
      .pipe(
        map((q) => transformFirebase<IAccount>(q)),
        map((account) => {
          return { id: id, ...(account as IAccount) };
        })
      );
  }

  getMyAccounts$ = (): Observable<IAccount[]> => {
    if (!this._myAccounts$) {
      this._myAccounts$ = this.auth.user$.pipe(
        switchMap((user) => {
          console.log(user);
          if (!user) {
            return of([]);
          }
          if (this.auth.isSuperAdmin) {
            return this.afs
              .collection<IAccount>('accounts', (ref) => ref.orderBy('name'))
              .valueChanges({
                idField: 'id',
              });
          } else {
            return this.auth.myAccessesMap$.pipe(
              switchMap((accessess) => {
                const ids = Object.keys(accessess);
                return getDocs<IAccount>(this.afs, 'accounts', ids);
              })
            );
          }
        })
      );
    }
    return this._myAccounts$;
  };

  // return of([]);
  // return this.afs
  //   .collection<Account>('accounts', (ref) =>
  //     ref.where('users', 'array-contains', this.afAuth.email).orderBy('name')
  //   )
  //   .valueChanges({
  //     idField: 'id',
  //   });
  // .get({ idField: 'id' })

  deleteAccount(account: IAccount) {
    this.afs
      .doc(`accounts/${account.id}`)
      .delete()
      .then(() => {
        console.log('deleted', account.id);
      })
      .catch((err) => console.log('err', err));
  }
}
