import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-page-not-found",
  template: `
    <h1 class="align-center">
      404 - THIS PAGE SHOULDN'T EXIST. PSSST!
    </h1>
  `,
  styles: [],
})
export class PageNotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
