import { Component, OnInit, OnDestroy } from '@angular/core';
import { LegalDocument, LEGAL_TYPE, ILegalDocument } from '../models/legal';
import { LegalsService } from '../services/legal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';
import { ProjectService } from '../services/project.service';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
})
export class LegalComponent implements OnInit, OnDestroy {
  legalTypes = Object.values(LEGAL_TYPE);
  accountId = '';
  projectId = '';
  public documents: LegalDocument[] = [];
  subscribtion = new Subscription();

  res: Observable<
    { name: Observable<string>; legals: Observable<LegalDocument[]> }[]
  >;

  constructor(
    public auth: AuthService,
    public legalService: LegalsService,
    private projectService: ProjectService,
    activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.accountId = activatedRoute.snapshot.paramMap.get('accountId');
    this.projectId = activatedRoute.snapshot.paramMap.get('projectId');

    this.res = this.auth.myAccesses$.pipe(
      map((accesses) => {
        return accesses
          .filter(
            (access) =>
              access.legal &&
              access.account === this.accountId &&
              (this.projectId
                ? access.project === this.projectId || !access.project
                : true)
          )
          .sort((access) => (access.account ? 1 : -1))
          .map((access) => {            
            return {
              name: access.project
                ? this.projectService
                    .getProject$(access.project)
                    .pipe(map((project) => project.name))
                : of('Account'),
              legals: this.legalService.getLegalsForAccess$(access),
            };
          });
      })
    );
  }

  editLegal(legal: ILegalDocument): void {
    let url = ['/account', legal.account];
    if (legal.project) {
      url = [...url, 'project', legal.project];
    }
    console.log(url);
    this.router.navigate([...url, 'legals-add', legal.id]);
  }

  ngOnDestroy(): void {
    this.subscribtion.unsubscribe();
  }

  ngOnInit(): void {}

  remove(document: LegalDocument): void {
    this.legalService.deleteLegal(document);
  }
}
