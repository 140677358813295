import { Component, OnInit, Input } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth/auth';
import { AuthService } from '../services/auth.service';
import { CustomValidators } from 'ngx-custom-validators';

@Component({
  selector: 'app-email-login',
  templateUrl: './email-login.component.html',
  styleUrls: ['./email-login.component.scss'],
})
export class EmailLoginComponent implements OnInit {
  form: FormGroup;

  type: 'login' | 'signup' | 'reset' = 'login';
  loading = false;

  serverMessage: string;

  constructor(private auth: AuthService, private fb: FormBuilder) {}

  ngOnInit() {
    let pass = new FormControl('', [
      Validators.minLength(8),
      Validators.required,
    ]);
    let passConfirm = new FormControl('', [
      Validators.required,
      CustomValidators.equalTo(pass),
    ]);
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: pass,
      passwordConfirm: passConfirm,
    });
  }

  changeType(val) {
    this.serverMessage = '';
    this.type = val;
  }

  get isLogin() {
    return this.type === 'login';
  }

  get isSignup() {
    return this.type === 'signup';
  }

  get isPasswordReset() {
    return this.type === 'reset';
  }

  get email() {
    return this.form.get('email');
  }
  get password() {
    return this.form.get('password');
  }

  get passwordConfirm() {
    return this.form.get('passwordConfirm');
  }

  async onSubmit() {
    this.loading = true;

    const email = this.email.value;
    const password = this.password.value;

    try {
      if (this.isLogin) {
        await this.auth.emailSignIn(email, password);
      }
      if (this.isSignup) {
        await this.auth.emailRegister(email, password);
      }
      if (this.isPasswordReset) {
        await this.auth.passwordReset(email);
        this.serverMessage = 'Check your email';
      }
    } catch (err) {
      this.serverMessage = err;
    }

    this.loading = false;
  }
}
