<mat-form-field appearance="outline">
  <mat-label>Select Users</mat-label>
  <mat-select [(value)]="selectedUsersIds" name="selectedUsers" multiple>
    <mat-option
      *ngFor="let user of (users$ | async)"
      [value]="user.id"
      >{{ user.email }} (user.displayName)</mat-option
    >
  </mat-select>
</mat-form-field>
<button mat-raised-button color="accent" (click)="close()">
  Add users
</button>
<!-- <pre>
  {{ selectedUsersIds | json }}
</pre> -->
