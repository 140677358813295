import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardsService } from '../services/dashboards.service';
import { IDashboard } from '../models/dashboard';
import { of, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-dashboard-views',
  templateUrl: './dashboard-views.component.html',
  styleUrls: ['./dashboard-views.component.scss'],
})
export class DashboardViewsComponent implements OnInit {
  projectId = '';
  dashboards$: Observable<IDashboard[]> = of([]);
  constructor(
    public auth: AuthService,
    private dashboardsService: DashboardsService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.projectId =
      this.router.routerState.root.firstChild.params['value']['projectId'] ||
      '';

    this.dashboards$ = dashboardsService.getDashboardsForProject(
      this.projectId
    );
  }

  // dsviews = [
  //   {
  //     name: 'Simcity Buildit UA Performance Overview',
  //     url:
  //       'https://datastudio.google.com/embed/reporting/1NnCQUqIlHwiT1ajGzj--qBlXG1Mdn6Fq/page/iRHJB',
  //   },
  //   {
  //     name: 'Simcity Buildit UA Campaign Detail',
  //     url:
  //       'https://datastudio.google.com/embed/reporting/1r37tn7jglRfoEZ8VkAl_Km4OH2UVIzYA/page/OLfJB',
  //   }

  // ];
  // constructor(private dashboard) {}

  ngOnInit(): void {}

  remove(view: IDashboard) {
    if (confirm(`Are you sure to delete ${view.name}`))
      this.dashboardsService.deleteDashboard(view);
  }
}
