import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ModalData {
  title: string;
  message: string;
  ok: string;
  cancel: string;
}

@Component({
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  title = 'Are you sure?';
  message = '';
  ok = 'OK';
  cancel = 'Cancel';
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) {
    if (!data) return;
    this.title = data.title || this.title;
    this.message = data.message || this.message;
    this.ok = data.ok || this.ok;
    this.cancel = data.cancel || this.cancel;
  }

  onClose(accept: boolean): void {
    this.dialogRef.close(accept);
  }
}
