import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AngularFireStorage,
  AngularFireStorageReference,
} from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { tap, finalize, switchMap, map } from 'rxjs/operators';
import { IIFile } from '../models/file';
import { transformFirebase } from './helpers';
import { AuthService } from './auth.service';

export enum FILE_TYPE {
  INVOICE = 'invoice',
  LEGAL = 'legal',
  DELIVERABLE = 'deliverable',
  MEDIA = 'media',
}

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  bucketPath = 'test';
  filesCol = 'files';
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore,
    private auth: AuthService
  ) {}

  public async listAllFiles(
    orderBy = 'created',
    asc = false,
    account = null,
    project = null
  ): Promise<IIFile[]> {
    const filesRef = await this.db
      .collection<IIFile>(this.filesCol, (ref) => {
        let reference = ref.orderBy(orderBy, asc ? 'asc' : 'desc');
        if (account) {
          reference = reference.where('account', '==', account);
        }
        if (project) {
          reference = ref.where('project', '==', project);
        }
        return reference;
      })
      .get()
      .toPromise();
    if (filesRef.empty) {
      return [];
    }
    const files = await filesRef.docs;
    return files.map((f) => transformFirebase(f.data()) as IIFile);
  }

  public listAllFiles$(orderBy = 'created', asc = false): Observable<IIFile[]> {
    return this.db
      .collection<IIFile>(this.filesCol, (ref) => {
        return ref.orderBy(orderBy, asc ? 'asc' : 'desc');
      })
      .valueChanges({
        idField: 'id',
      })
      .pipe(
        map((files) => {
          return files.map((f) => transformFirebase(f));
        })
      );
  }

  public async deleteFile(file: IIFile): Promise<boolean> {
    var desertRef = this.storage.ref(file.path);
    desertRef.delete();
    this.db.doc(`${this.filesCol}/${file.id}`).delete();
    return true;
  }

  public async deleteFileByDownloadURL(url: string): Promise<boolean> {
    try {
      const docsRef = await this.db
        .collection(this.filesCol, (ref) => {
          return ref.where('downloadURL', '==', url).limit(1);
        })
        .get()
        .toPromise();
      if (docsRef.empty) {
        console.error('no doc found with that download url');
        return;
      }
      const docs = await docsRef.docs;
      console.log(docs);
      const doc = docs[0];
      await this.deleteFile(doc.data() as IIFile);
      return true
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public uploadFile(
    file: File,
    account: string,
    project: string,
    type: FILE_TYPE
  ): {
    percentage: Observable<number>;
    snapshot: Observable<any>;
    storageRef: AngularFireStorageReference;
  } {
    // The storage path
    const path = `${this.bucketPath}/${Date.now()}_${file.name}`;
    // Reference to storage bucket
    const storageRef = this.storage.ref(path);
    // The main task
    const task = this.storage.upload(path, file);
    // Progress monitoring
    const percentage = task.percentageChanges();

    const snapshot = task.snapshotChanges().pipe(
      // The file's download URL
      finalize(async () => {
        console.log('finalilze');
        let downloadURL = await storageRef.getDownloadURL().toPromise();
        // this.fileUrl.emit(this.downloadURL);
        // this.db.doc().set()
        const fileDoc = {
          downloadURL: downloadURL,
          path,
          name: file.name,
          author: this.auth.email || 'unauthenticated',
          created: new Date(),
          type: type,
          size: file.size,
          project,
          account,
        };
        console.log('fileDOC', fileDoc);
        // this.db.doc(`${this.filesCol}/${path}`).set(fileDoc);

        this.db.collection<IIFile>(this.filesCol).add(fileDoc);
      })
    );

    // console.log('{ percentage, snapshot, url }');
    // console.log({ percentage, snapshot, url: downloadURL });
    return { percentage, snapshot, storageRef };
  }
}
