import { Injectable } from '@angular/core';
import { IUser } from '../models/user';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';

import { transformFirebase } from './helpers';
import { AuthService } from './auth.service';
import { IAccess, getAccessId, ACCESS_TYPE } from '../models/access';

@Injectable({
  providedIn: 'root',
})
export class AccessService {
  // public accesses$: Observable<Access[]>;
  // public accesses: Access[];
  // public accessesMap: { [id: string]: Access } = {};
  constructor(private afs: AngularFirestore) {
    // this.accesses$ = this.afs
    //   .collection<any>(`accesses`, (ref) => ref.orderBy('project', 'desc'))
    //   .valueChanges({
    //     idField: 'id',
    //   })
    //   .pipe(
    //     map((accesses) => accesses.map((q) => transformFirebase<Access>(q)))
    //   );
    // this.accesses$.subscribe((accesses) => {
    //   this.accesses = accesses;
    //   accesses.forEach((access) => (this.accessesMap[access.id] = access));
    // });
  }

  createAccess(
    account: string,
    project: string,
    user: string,
    createdBy: string
  ) {
    const id = getAccessId(account, project, user);
    this.afs
      .collection<IAccess>(`accesses`)
      .doc(id)
      .set({
        id,
        account,
        project,
        user,
        createdBy,
        legal: false,
        invoices: false,
        dashboards: false,
        delivery: false,
        admin: false,
      })

      .then(() => {
        console.log('pls');
      })
      .catch(alert);
  }

  updateAccess({
    account,
    project,
    user,
    legal,
    invoices,
    dashboards,
    delivery,
    admin,
  }: IAccess) {
    const id = getAccessId(account, project, user);
    this.afs
      .collection<IAccess>(`accesses`)
      .doc(id)
      .set(
        {
          id,
          account,
          project,
          user,
          legal,
          invoices,
          dashboards,
          delivery,
          admin,
        },
        {
          merge: true,
        }
      )
      .then(() => {
        console.log('pls');
      })
      .catch(alert);
  }

  getAccess(id: string): Observable<IAccess> {
    return this.afs
      .doc<IAccess>(`accesses/${id}`)
      .valueChanges()
      .pipe(
        map((q) => transformFirebase<IAccess>(q)),
        map((access) => {
          return { id: id, ...access };
        })
      );
  }

  getAccessesForProject(projectId: string): Observable<IAccess[]> {
    return this.afs
      .collection<IAccess>(`accesses`, (ref) =>
        ref.where('project', '==', projectId)
      )
      .valueChanges({ idField: 'id' })
      .pipe(map((q) => q.map((item) => transformFirebase<any>(item))));
  }

  getAccessesForAccount(accountId: string): Observable<IAccess[]> {
    return this.afs
      .collection<IAccess>(`accesses`, (ref) =>
        ref.where('account', '==', accountId).where('project', '==', '')
      )
      .valueChanges({ idField: 'id' })
      .pipe(map((q) => q.map((item) => transformFirebase<any>(item))));
  }

  getAccessesForUser(email: string): Observable<IAccess[]> {
    return this.afs
      .collection<IAccess>(`accesses`, (ref) => ref.where('user', '==', email))
      .valueChanges({ idField: 'id' })
      .pipe(map((q) => q.map((item) => transformFirebase<any>(item))));
  }

  deleteAccess(access: IAccess) {
    const id = getAccessId(access.account, access.project, access.user);
    this.afs
      .doc(`accesses/${id}`)
      .delete()
      .then(() => {
        console.log('yess', id);
      })
      .catch((err) => console.log('err', err));
  }
}
