import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { IInvoice, INVOICE_STATUS, CURRENCY } from '../models/invoice';
import {
  of,
  Observable,
  combineLatest,
  zip,
  forkJoin,
  Subscription,
  merge,
  concat,
} from 'rxjs';
import { InvoicesService } from '../services/invoices.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  switchMap,
  map,
  first,
  flatMap,
  tap,
  concatMap,
  concatAll,
  mapTo,
} from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../_components/confirm-dialog/confirm-dialog.component';
import { ProjectService } from '../services/project.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesComponent implements OnInit, OnDestroy {
  projectId = '';
  accountId = '';

  subscribtion = new Subscription();
  invoicesGrouping: IInvoice[][] = [];
  invoicesGroupingNames: string[] = [];
  projectsInvoices$: Observable<Observable<IInvoice[]>>[] = [];
  accountInvoices$: Observable<IInvoice[]> = null;

  INVOICE_STATUS = INVOICE_STATUS;
  CURRENCY = CURRENCY;

  res: Observable<
    { name: Observable<string>; invoices: Observable<IInvoice[]> }[]
  >;

  constructor(
    public auth: AuthService,
    public dialog: MatDialog,
    private invoicesService: InvoicesService,
    private router: Router,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef
  ) {
    this.projectId =
      this.router.routerState.root.firstChild.params['value']['projectId'] ||
      '';
    this.accountId =
      this.router.routerState.root.firstChild.params['value']['accountId'] ||
      '';

    this.res = this.auth.myAccesses$.pipe(
      map((accesses) => {
        return accesses
          .filter(
            (access) =>
              access.invoices &&
              access.account === this.accountId &&
              (this.projectId
                ? access.project === this.projectId || !access.project
                : true)
          )
          .sort((access) => (access.account ? 1 : -1))
          .map((access) => {
            return {
              name: access.project
                ? this.projectService
                    .getProject$(access.project)
                    .pipe(map((project) => project.name))
                : of('Account'),
              invoices: this.invoicesService.getInvoicesForAccess$(access),
            };
          });
      })
    );
  }

  markAsPaid(invoice: IInvoice): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          message: `If you paid invoice '${invoice.name}', please confirm.`,
          title: 'Are you sure?',
        },
      })
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.invoicesService.updateInvoice({
            ...invoice,
            status: INVOICE_STATUS.PAID,
          });
        }
      });
  }

  deleteInvoice(invoice: IInvoice): void {
    this.invoicesService.deleteInvoice(invoice);
  }

  editInvoice(invoice: IInvoice): void {
    let url = ['/account', invoice.account];
    if (invoice.project) {
      url = [...url, 'project', invoice.project];
    }
    this.router.navigate([...url, 'invoices-add', invoice.id]);
  }

  ngOnDestroy(): void {
    this.subscribtion.unsubscribe();
  }

  ngOnInit(): void {}
}
