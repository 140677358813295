<div class="container">
  <div class="row">
    <div class="card">
      <div class="card-body">
        <h1 class="text-center">Add Deliverable</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <mat-card class="col-md-12">
      <mat-card-content>
        <form>
          <mat-form-field appearance="outline">
            <mat-label>name </mat-label>
            <input
              matInput
              placeholder="name"
              name="name"
              [(ngModel)]="deliverable.name"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Deliverable Type</mat-label>
            <mat-select [(value)]="deliverable.type">
              <mat-option
                *ngFor="let deliverableType of deliverableTypes"
                [value]="deliverableType"
                >{{ deliverableType }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <br />

          <br />
          <mat-label>Description</mat-label>
          <app-media-editor
            [(text)]="deliverable.description"
          ></app-media-editor>
          <br />

          <a
            *ngIf="deliverable.url"
            mat-raised-button
            [href]="deliverable.url"
            color="accent"
          >
            <mat-icon>cloud_download</mat-icon>
            Current Document Link
          </a>
          <app-uploader
            [fileType]="fileType"
            title="Upload Document"
            (fileDownloadURL)="uploaded($event)"
          ></app-uploader>
        </form>
      </mat-card-content>
      <mat-card-actions>
        <button
          mat-raised-button
          color="accent"
          [routerLink]="[
            '/account',
            accountId,
            'project',
            projectId,
            'deliverables'
          ]"
          (click)="submit()"
        >
          <mat-icon>save</mat-icon>
          Save
        </button>
      </mat-card-actions>
    </mat-card>
    <!-- <mat-card class="col-md-6">
      <mat-card-header>
        <mat-card-title>info</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <pre>
        {{ deliverable | json }}
      </pre
        >
      </mat-card-content>
    </mat-card> -->
  </div>
</div>
