import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';

import { GoogleSigninDirective } from './_directives/google-signin.directive';

import { SafePipe } from './pipes/safe.pipe';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { SafeHtmlPipe } from './pipes/safehtml.pipe';
import { DocPipe } from './pipes/doc.pipe';
import { UrlsToHrefsPipe } from './pipes/urls-to-hrefs.pipe';
import { NgxFilesizeModule } from 'ngx-filesize';

import { UserProfileComponent } from './user-profile/user-profile.component';
import { ProjectOverviewComponent } from './project-overview/project-overview.component';
import { LoginComponent } from './login/login.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { NavComponent } from './nav/nav.component';
import { DeliverablesComponent } from './deliverables/deliverables.component';
import { ProductsComponent } from './products/products.component';
import { StatusComponent } from './status/status.component';
import { LegalComponent } from './legal/legal.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { DashboardViewsComponent } from './dashboard-views/dashboard-views.component';
import { InfoComponent } from './info/info.component';
import { SharedModule } from './_shared/shared.module';
import { AccountListComponent } from './account-list/account-list.component';
import { AccountOverviewComponent } from './account-overview/account-overview.component';
import { ProjectAddComponent } from './admin/project-add/project-add.component';
import { LegalAddComponent } from './admin/legal-add/legal-add.component';
import { DeliverableAddComponent } from './admin/deliverable-add/deliverable-add.component';
import { InvoicesAddComponent } from './invoices-add/invoices-add.component';
import { DashboardsAddComponent } from './dashboard-views-add/dashboard-views-add.component';
import { ProjectSelectorComponent } from './_components/project-selector/project-selector.component';
import { AccountsAddComponent } from './account-add/account-add.component';
import { UserSelectorComponent } from './_components/user-selector/user-selector.component';
import { DropzoneDirective } from './_directives/dropzone.directive';
import { UploaderComponent } from './uploader/uploader.component';
import { UploaderTaskComponent } from './uploader-task/uploader-task.component';
import { AccessAddComponent } from './access-add/access-add.component';
import { ExpertAddComponent } from './expert-add/expert-add.component';
import { ExpertListComponent } from './expert-list/expert-list.component';
import { ConfirmDialogComponent } from './_components/confirm-dialog/confirm-dialog.component';
import { DeliverableCardComponent } from './deliverable-card/deliverable-card.component';

import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { environment } from 'src/environments/environment';
import { AdminComponent } from './admin/admin.component';
import { FilesListTableComponent } from './files-list-table/files-list-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MediaEditorComponent } from './media-editor/media-editor.component';
import { EmailLoginComponent } from './email-login/email-login.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,

    NavComponent,

    ProjectOverviewComponent,
    DeliverablesComponent,
    UserProfileComponent,
    OnboardingComponent,
    ProductsComponent,
    StatusComponent,
    LegalComponent,
    InvoicesComponent,
    DashboardViewsComponent,
    InfoComponent,
    AccountListComponent,
    AccountOverviewComponent,

    GoogleSigninDirective,
    SafePipe,
    SafeHtmlPipe,
    RelativeTimePipe,
    DocPipe,
    UrlsToHrefsPipe,
    DropzoneDirective,

    InvoicesAddComponent,
    DashboardsAddComponent,
    LegalAddComponent,
    ProjectAddComponent,
    DeliverableAddComponent,
    AccountsAddComponent,
    AccessAddComponent,

    ProjectSelectorComponent,
    UserSelectorComponent,
    UploaderComponent,
    UploaderTaskComponent,
    ExpertAddComponent,
    ExpertListComponent,
    ConfirmDialogComponent,
    DeliverableCardComponent,
    AdminComponent,
    FilesListTableComponent,
    MediaEditorComponent,
    EmailLoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    AngularFireAnalyticsModule,

    EditorModule,

    SharedModule,

    NgxFilesizeModule,
  ],
  providers: [
    UserTrackingService,
    ScreenTrackingService,
    // {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false, }}
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
