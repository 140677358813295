<div class="container">
  <div class="row">
    <div class="card">
      <div class="card-body">
        <h1 class="text-center">Add Dashboard</h1>
      </div>
    </div>
  </div>
  <mat-card class="col-md-12">
    <!-- <mat-card-header>
      <mat-card-title>Add dashboard</mat-card-title>
    </mat-card-header> -->
    <mat-card-content>
      <mat-form-field appearance="outline">
        <mat-label>name</mat-label>
        <input matInput [(ngModel)]="dashboard.name" />
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline">
        <mat-label>url</mat-label>
        <input matInput [(ngModel)]="dashboard.url" />
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline">
        <mat-label>height</mat-label>
        <input type="number" matInput [(ngModel)]="dashboard.height" />
      </mat-form-field>
      <br />
      <mat-label>Description</mat-label>
      <editor
        [init]="{
          base_url: '/tinymce',
          suffix: '.min',
          height: 200,
          menubar: true,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help'
        }"
        [(ngModel)]="dashboard.description"
        [ngModelOptions]="{ standalone: true }"
      ></editor>
      <br />
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-raised-button
        color="accent"
        [routerLink]="[
          '/account',
          accountId,
          'project',
          projectId,
          'dashboards'
        ]"
        (click)="submit()"
      >
        <mat-icon>save</mat-icon>Save Dashboard
      </button>
    </mat-card-actions>
  </mat-card>
</div>
