import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, of, Subscription } from 'rxjs';
import { map, shareReplay, switchMap, filter } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ProjectService } from '../services/project.service';
import {
  Router,
  ActivatedRoute,
  RouterEvent,
  NavigationEnd,
} from '@angular/router';
import { IProject } from '../models/project';
import { IAccount } from '../models/account';
import { AccountService } from '../services/account.service';
import { ACCESS_TYPE } from '../models/access';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent implements OnInit, OnDestroy {
  projectId = '';
  accountId = '';
  activeAccount = '';
  activeProject = '';
  accounts$: Observable<IAccount[]> = of([]);
  projects$: Observable<IProject[]> = of([]);
  projects = [];
  ACCESS_TYPE = ACCESS_TYPE;
  logoUrl = ['account'];
  profileUrl = ['profile'];
  adminUrl = ['admin'];

  subscriptions = new Subscription();

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  logged: boolean;

  constructor(
    public auth: AuthService,
    public projectService: ProjectService,
    public accountService: AccountService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef
  ) {
    this.accounts$ = this.accountService.getMyAccounts$();
    this.projects$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      switchMap((event: RouterEvent) => {
        this.projectId =
          this.router.routerState.root.firstChild.params['value'][
            'projectId'
          ] || '';
        this.accountId =
          this.router.routerState.root.firstChild.params['value'][
            'accountId'
          ] || '';        
        this.logoUrl = this.getLinkWithActiveAccountProject(
          ['account'],
          this.accountId,
          this.projectId
        );
        this.profileUrl = this.getLinkWithActiveAccountProject(
          ['profile'],
          this.accountId,
          this.projectId
        );
        this.adminUrl = this.getLinkWithActiveAccountProject(
          ['admin'],
          this.accountId,
          this.projectId
        );

        return this.projectService.getMyProjects(this.accountId);
      })
    );

    this.subscriptions.add(
      this.auth.myAccesses$.subscribe(() => {
        this.changeDetector.markForCheck();
      })
    );

    this.subscriptions.add(
      this.projects$.subscribe((projects) => {
        this.activeProject =
          projects.find((project) => project.id == this.projectId)?.name ?? '';
      })
    );

    this.subscriptions.add(
      this.auth.isLoggedIn$.subscribe((logged) => {
        this.logged = logged;
        this.changeDetector.markForCheck();
      })
    );
  }

  getLinkWithActiveAccountProject(
    firstSegments: string[],
    accountId: string,
    projectId: string
  ) {
    if (accountId && projectId) {
      return [...firstSegments, accountId, projectId];
    }
    if (accountId) {
      return [...firstSegments, accountId];
    }
    return firstSegments;
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
