<!-- 
<div class="container">
  <mat-card class="col-md-6">
    <mat-card-header>
      <mat-card-title>Add invoice</mat-card-title>      
    </mat-card-header>
    <mat-card-content>
  
      
      <mat-form-field appearance="outline">
        <mat-label>name</mat-label>
        <input matInput [(ngModel)]="invoice.name" />
      </mat-form-field>
      <br />      
      <project-selector [(ngModel)]="invoice.project"></project-selector>
     
      <br />
      <mat-form-field appearance="outline">
        <mat-label>price $</mat-label>
        <input type="number" matInput [(ngModel)]="invoice.price" />
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline">
        <mat-label>url</mat-label>
        <input matInput [(ngModel)]="invoice.url" />
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline">
        <mat-label>Invoice status</mat-label>
        <mat-select [(value)]="invoice.status">
          <mat-option *ngFor="let status of status_types" [value]="status">{{status}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br />
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="accent" (click)="submit()">Save Invoice</button>
    </mat-card-actions>
  </mat-card>
</div> -->
<!-- {{ invoice | json }} -->
<div class="container">
  <div class="row">
    <div class="card">
      <div class="card-body">
        <h1 class="text-center">Add Project</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <mat-card class="col-md-12">
      <!-- <mat-card-header>
        <mat-card-title>Add Project</mat-card-title>
      </mat-card-header> -->
      <mat-card-content>
        <mat-form-field appearance="outline">
          <mat-label>name</mat-label>
          <input
            matInput
            placeholder="name"
            name="name"
            [(ngModel)]="project.name"
          />
        </mat-form-field>
        <section>
          <span class="example-list-section">
            <table>
              <tr *ngFor="let user of project.users">
                <td>
                  {{ user.photoURL }}
                </td>
                <td>
                  {{ user.email }}
                </td>
                <td>
                  {{ user.displayName }}
                </td>
              </tr>
            </table>
            <!-- <button mat-raised-button color="accent" (click)="addUsers()">
                Add users
              </button>
              <br />
              <button mat-raised-button color="accent" (click)="addExperts()">
                Add Expert Team
              </button>
              <br /> -->
          </span>
        </section>

        <section class="onboarding">
          <h2>Onboarding Steps</h2>
          <ol *ngFor="let group of project.onboardingGroups">
            <h3>
              {{ group.name }}
              <button mat-icon-button color="warn" (click)="removeGroup(group)">
                <mat-icon>delete</mat-icon>
              </button>
            </h3>
            <li *ngFor="let step of group.steps">
              <mat-checkbox color="primary" [(ngModel)]="step.done"
                >{{ step.name }}
              </mat-checkbox>
              <button
                mat-icon-button
                color="warn"
                (click)="removeStep(group, step)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </li>
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input matInput #stepname />
              <!-- (keyup.enter)="addOnboardingStep(stepname.value)"  -->
            </mat-form-field>
            <button
              mat-raised-button
              color="accent"
              (click)="addOnboardingStep(group, stepname.value)"
            >
              Add New Step
            </button>
          </ol>
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput #groupname />
          </mat-form-field>
          <button
            mat-raised-button
            color="accent"
            (click)="addOnboardingGroup(groupname.value)"
          >
            Add New Group
          </button>
        </section>
        <br />
        <h3>Current Logo Cover</h3>
        <img width="200" *ngIf="project.logo" [src]="project.logo" />
        <app-uploader
        [fileType]="fileType"
          title="Upload Project Logo"
          (fileDownloadURL)="uploadedLogo($event)"
        ></app-uploader>
        <br />
        <app-expert-list
          [(experts)]="project.experts"
          [edit]="true"
        ></app-expert-list>
      </mat-card-content>
      <mat-card-actions>
        <button
          mat-raised-button
          color="accent"
          [routerLink]="['/account', accountId]"
          (click)="submit()"
        >
          <mat-icon>save</mat-icon>
          Save
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
