<mat-toolbar color="primary" [hidden]="!logged">
  <button *ngIf="!(isHandset$ | async)" [routerLink]="logoUrl" mat-button>
    <img
      id="imglogo"
      class="d-none d-sm-block"
      src="./assets/img/superscale_black.png"
      alt=""
    />
  </button>
  <button
    type="button"
    aria-label="Toggle sidenav"
    mat-icon-button
    *ngIf="logged && (isHandset$ | async)"
    (click)="drawer.toggle()"
  >
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <div *ngIf="logged">
    <button mat-button [matMenuTriggerFor]="menu" aria-label="Menu">
      {{ activeProject ? activeProject : "Please select project" }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button *ngIf="!(projects$ | async)?.length" mat-menu-item>
        <span>No projects</span>
      </button>
      <button
        *ngFor="let project of projects$ | async"
        [routerLink]="[
          '/account',
          project.account,
          'project',
          project.id,
          'overview'
        ]"
        mat-menu-item
      >
        <span>{{ project.name }}</span>
      </button>
    </mat-menu>
  </div>
  <span class="spacer"> </span>
  <div *ngIf="logged">
    <div>
      <button mat-button [matMenuTriggerFor]="accountMenu" aria-label="Menu">
        <mat-icon>account_circle</mat-icon>
        {{ (auth.user$ | async)?.email }}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #accountMenu="matMenu">
        <button
          *ngFor="let account of accounts$ | async"
          [routerLink]="['account', account.id]"
          mat-menu-item
        >
          <mat-icon>account_balance</mat-icon>
          <span>{{ account.name }}</span>
        </button>
        <mat-divider></mat-divider>

        <button mat-menu-item color="accent" [routerLink]="profileUrl">
          <mat-icon>supervisor_account</mat-icon>
          Profile Settings
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item color="accent" (click)="auth.signOut()">
          <mat-icon>logout</mat-icon>
          Log out
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav
    #drawer
    class="sidenav"
    [hidden]="!logged"
    fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar>
      <mat-toolbar-row></mat-toolbar-row>
    </mat-toolbar>

    <mat-nav-list>
      <!-- PROJECT SECTION -->
      <ng-container *ngIf="projectId && accountId">
        <a
          *ngIf="auth.hasAccess(ACCESS_TYPE.access, accountId, projectId)"
          mat-list-item
          [routerLink]="[
            'account',
            accountId,
            'project',
            projectId,
            'overview'
          ]"
        >
          <mat-icon matListIcon>dashboard</mat-icon>Overview
        </a>
        <a
          *ngIf="auth.hasAccess(ACCESS_TYPE.dashboards, accountId, projectId)"
          mat-list-item
          [routerLink]="[
            'account',
            accountId,
            'project',
            projectId,
            'dashboards'
          ]"
        >
          <mat-icon matListIcon>analytics</mat-icon>Dashboards
        </a>
        <a
          *ngIf="auth.hasAccess(ACCESS_TYPE.delivery, accountId, projectId)"
          mat-list-item
          [routerLink]="[
            'account',
            accountId,
            'project',
            projectId,
            'deliverables'
          ]"
        >
          <mat-icon matListIcon>picture_as_pdf</mat-icon>Updates &amp; Reports
        </a>
        <a
          *ngIf="auth.hasAccess(ACCESS_TYPE.legal, accountId, projectId)"
          mat-list-item
          [routerLink]="['account', accountId, 'project', projectId, 'legals']"
        >
          <mat-icon matListIcon>text_snippet</mat-icon>Legal
        </a>
        <a
          *ngIf="auth.hasAccess(ACCESS_TYPE.invoices, accountId, projectId)"
          mat-list-item
          [routerLink]="[
            'account',
            accountId,
            'project',
            projectId,
            'invoices'
          ]"
        >
          <mat-icon matListIcon>receipt_long</mat-icon>Invoices
        </a>
        <mat-divider></mat-divider>
        <a
          *ngIf="auth.hasAccess(ACCESS_TYPE.admin, accountId, projectId)"
          mat-list-item
          routerLinkActive=""
          [routerLink]="[
            'account',
            accountId,
            'project',
            projectId,
            'access-add'
          ]"
        >
          <mat-icon matListIcon>admin_panel_settings</mat-icon>Administration</a
        >
      </ng-container>

      <!-- ACCOUNT SECTION -->
      <ng-container *ngIf="accountId && !projectId">
        <a
          *ngIf="auth.hasAccess(ACCESS_TYPE.access, accountId, '')"
          mat-list-item
          [routerLink]="['account', accountId]"
        >
          <mat-icon matListIcon>dashboard</mat-icon>Account Overview
        </a>
        <a
          *ngIf="auth.hasAccess(ACCESS_TYPE.legal, accountId, '')"
          mat-list-item
          [routerLink]="['account', accountId, 'legals']"
        >
          <mat-icon matListIcon>text_snippet</mat-icon>Legal
        </a>
        <a
          *ngIf="auth.hasAccess(ACCESS_TYPE.invoices, accountId, '')"
          mat-list-item
          [routerLink]="['account', accountId, 'invoices']"
        >
          <mat-icon matListIcon>receipt_long</mat-icon>Invoices
        </a>
        <!-- <a mat-list-item [routerLink]="['account', accountId, 'products']">
          <mat-icon matListIcon>shop</mat-icon>Marketplace
        </a> -->
        <a
          *ngIf="auth.hasAccess(ACCESS_TYPE.access, accountId, '')"
          mat-list-item
          [routerLink]="['account', accountId, 'info']"
        >
          <mat-icon matListIcon>info</mat-icon>Billing Information
        </a>
        <mat-divider></mat-divider>
        <a
          *ngIf="auth.hasAccess(ACCESS_TYPE.admin, accountId, '')"
          mat-list-item
          [routerLink]="['account', accountId, 'access-add']"
        >
          <mat-icon matListIcon>admin_panel_settings</mat-icon>Administration</a
        >
      </ng-container>
      <a mat-list-item [routerLink]="profileUrl">
        <mat-icon matListIcon>supervisor_account</mat-icon>Profile Settings</a
      >      
      <mat-divider></mat-divider>
        <a
          *ngIf="auth.isSuperAdmin"
          mat-list-item
          [routerLink]="adminUrl"
        >
          <mat-icon matListIcon>build</mat-icon>Portal Admin</a
        >
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
<!-- <div class="row">
  <span class="text-center">SuperScale &copy; 2020 </span>
</div> -->
