import { Component, OnInit } from '@angular/core';
import { Subscription, Observable, of } from 'rxjs';
import { IAccount } from '../models/account';
import { ActivatedRoute } from '@angular/router';
import { IProject } from '../models/project';
import { ProjectService } from '../services/project.service';
import { AccountService } from '../services/account.service';
import { UserService } from '../services/user.service';
import { IUser } from '../models/user';
import { IAccesses } from '../models/accesses';
import { AccessService } from '../services/accesses.service';
import { IAccess, ACCESS_TYPE } from '../models/access';
import { Validators, FormControl, NgModel } from '@angular/forms';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-access-add',
  templateUrl: './access-add.component.html',
  styleUrls: ['./access-add.component.scss'],
})
export class AccessAddComponent implements OnInit {
  subscription = new Subscription();
  ACCESS_TYPE = ACCESS_TYPE;
  accountId = '';
  projectId = '';

  emailValue = '';
  email = new FormControl('', [
    Validators.email,
    Validators.required,
  ]);

  accesses$: Observable<IAccess[]>;
  users$: Observable<IUser[]>;

  constructor(
    public usersService: UserService,
    private accountsService: AccountService,
    private projectsService: ProjectService,
    private accessesService: AccessService,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute
  ) {
    this.accountId = activatedRoute.snapshot.paramMap.get('accountId') || '';
    this.projectId = activatedRoute.snapshot.paramMap.get('projectId') || '';
    this.users$ = this.usersService.users$;
    this.accesses$ = this.projectId
      ? this.accessesService.getAccessesForProject(this.projectId)
      : this.accessesService.getAccessesForAccount(this.accountId);
  }

  ngOnInit(): void {}

  submit(): void {
    // if (this.id) {
    //   this.accountsService.updateAccount(this.access);
    // } else {
    //   this.accountsService.createAccount(this.access);
    // }
  }

  addAccess() {  
    if (!this.email.invalid) {
      this.accessesService.createAccess(
        this.accountId,
        this.projectId,
        this.email.value,
        this.auth.email
      );
    }
  }

  uniqueAccess(index: number, access: IAccess) {
    return access.id;
  }

  removeAccess(access: IAccess) {
    this.accessesService.deleteAccess(access);
  }

  public changeAccess(access: IAccess, type: ACCESS_TYPE, add: boolean) {
    access[type] = add;      
    this.accessesService.updateAccess(access);
  }
}
