<ng-container *ngIf="deliverable; else emptyDeliverable">
  <div class="card">
    <div class="card-body">
      <h1 class="card-title">
        <mat-icon>picture_as_pdf</mat-icon>
        {{ deliverable.name }}
      </h1>
      <p>{{ deliverable.created | date }}</p>
      <p class="card-text" [innerHTML]="deliverable.description | safehtml"></p>
      <p>
        by <b>{{ deliverable.authorName }}</b>
      </p>
      <a
        *ngIf="deliverable.url"
        [href]="deliverable.url"
        mat-flat-button
        color="primary"
        target="_blank"
        rel="noopener"
        matTooltip="Visit Deliverable"
        [matTooltipPosition]="'above'"
      >
        <i class="material-icons">link</i>
        Open Report
      </a>
      <ng-container *ngIf="showAdminControls">
        <br />
        <br />
        <mat-divider></mat-divider>
        <br />
        <a
          [routerLink]="['../deliverables-add/', deliverable.id]"
          mat-stroked-button
          color="primary"
          matTooltip="Edit Deliverable"
          [matTooltipPosition]="'above'"
        >
          <i class="material-icons">edit</i>
          Edit
        </a>

        <a
          (click)="remove(deliverable)"
          mat-stroked-button
          color="warn"
          matTooltip="Delete Deliverable"
          [matTooltipPosition]="'above'"
        >
          <i class="material-icons">delete</i>
          Delete
        </a>
      </ng-container>
    </div>
  </div>
</ng-container>
<ng-template #emptyDeliverable>
  <mat-error>Error loading deliverable..</mat-error>
</ng-template>
