<div class="container">
  <div class="row">
    <div class="card">
      <div class="card-body">
        <h1 class="text-center">Administration - Accesses</h1>
      </div>
    </div>
  </div>
  <div class="row">
    <mat-card class="col-md-6 mx-auto">
      <mat-form-field appearance="outline">
        <mat-label>User Email</mat-label>
        <input
          type="email"
          matInput
          placeholder="Email"
          [formControl]="email"
          [class.is-invalid]="email.invalid && email.touched"
          pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        />
        <mat-error
          *ngIf="
            email.errors &&
            (email.invalid || email.touched) &&
            email.errors.pattern
          "
          >Please enter a valid email address.</mat-error
        >
        <mat-error
          *ngIf="
            email.errors &&
            (email.invalid || email.touched) &&
            email.errors.required
          "
          >Email is required.</mat-error
        > </mat-form-field
      ><br />
      <button mat-raised-button color="accent" (click)="addAccess()">
        <mat-icon>add</mat-icon>Invite New User
      </button>
    </mat-card>
  </div>

  <div class="row">
    <table class="table">
      <thead>
        <tr>
          <th>Username</th>
          <ng-container *ngIf="this.projectId">
            <th>Dashboards</th>
            <th>Updates</th>
          </ng-container>
          <th>Legal</th>
          <th>Invoices</th>
          <th>Admin</th>
          <th>Remove User</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let access of (accesses$ | async); trackBy:uniqueAccess">
          <td>{{ access.user }}</td>
          <ng-container *ngIf="this.projectId">
            <td>
              <mat-slide-toggle
                [(ngModel)]="access.dashboards"
                (change)="
                  changeAccess(access, ACCESS_TYPE.dashboards, $event.checked)
                "
              ></mat-slide-toggle>
            </td>
            <td>
              <mat-slide-toggle
                [(ngModel)]="access.delivery"
                (change)="
                  changeAccess(access, ACCESS_TYPE.delivery, $event.checked)
                "
              ></mat-slide-toggle>
            </td>
          </ng-container>
          <td>
            <mat-slide-toggle
              [(ngModel)]="access.legal"
              (change)="changeAccess(access, ACCESS_TYPE.legal, $event.checked)"
            ></mat-slide-toggle>
          </td>
          <!-- (change)="changeAccess(ACCESS_TYPE.legal, user.email, $event.checked)" -->
          <td>
            <mat-slide-toggle
              [(ngModel)]="access.invoices"
              (change)="
                changeAccess(access, ACCESS_TYPE.invoices, $event.checked)
              "
            ></mat-slide-toggle>
          </td>
          <!-- (change)="changeAccess(ACCESS_TYPE.invoices, user.email, $event.checked)" -->
          <td>
            <mat-slide-toggle
              [(ngModel)]="access.admin"
              (change)="changeAccess(access, ACCESS_TYPE.admin, $event.checked)"
            ></mat-slide-toggle>
          </td>
          <!-- (change)="changeAccess(ACCESS_TYPE.admin, user.email, $event.checked)" -->
          <td>
            <button
              mat-raised-button
              color="warn"
              (click)="removeAccess(access)"
            >
              <mat-icon>remove</mat-icon>Remove
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- <div class="row">
    <mat-card class="col-md-12">
      <mat-card-content>
        <br />
      </mat-card-content>
      <mat-card-actions>
        <button
          mat-raised-button
          color="accent"
          [routerLink]="['/account']"
          (click)="submit()"
        >
          <mat-icon>save</mat-icon>
          Save
        </button>
      </mat-card-actions>
    </mat-card>
  
  </div> -->
</div>
